import { styled } from "@mui/system";
import { Button, Grid } from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
  

export const HeaderContanier = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  padding: "7px 9px 7px 26px",
  borderBottom: "1px solid #F0F0F0"
});

export const StyledButton = styled(Button)({
  backgroundColor: "#E8E8E8"
});

export const StyledArrow = styled(ArrowForwardIosIcon)({
  height: "15px",
  marginLeft: "10px",
});