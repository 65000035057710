import { useQuery, useLazyQuery } from "@apollo/client";
import { GET_TASK_STATUS, GET_TASKS } from "query/cgsServise";
import Button from "@mui/material/Button";
import { useStyles } from "./TaskStatus.style";
import { ILookupData, ITask} from "../Cgs.types";
import Pagination from "@mui/material/Pagination";
import { useState } from "react";
import moment from "moment";


export const TaskProgressStatus = () => {

  const getColorByStatus = (status?: string) => {
    switch(status){
    case "TaskStatus_Failed": {
      return "#DC143C";
    }
    case "TaskStatus_InProgress": {
      return "yellow";
    }
    case "TaskStatus_Finished": {
      return "lightgreen";
    }
    default: "LightSteelBlue";
    }
  };

  const classes = useStyles();
  const [page, setPage] = useState(1);
  const handleChange = (_: any, value: number) => {
    setPage(value);
    const currPageAsk = task && task.number + 11 - (value * 11);
    getTaskList({ variables: {
      offset: currPageAsk,
      limit: 10,
    }});
  };

  const { data: status } = useQuery<ILookupData>(GET_TASK_STATUS, {
    fetchPolicy: "no-cache",
    pollInterval: 15000,
  });

  const [getTaskList, {data: taskListData} ] = useLazyQuery<ILookupData>(GET_TASKS, {
    fetchPolicy: "no-cache",
  });

  const taskList = taskListData?.lookup.cgsAdmin.tasks;

  const task = status?.lookup?.cgsAdmin?.currentTaskProgress?.task;
  const totalTasksInQueue = status?.lookup?.cgsAdmin?.currentTaskProgress?.totalTasksInQueue;

  return (
    <div className={classes.wrapper}>
      <div className={classes.label}>Current Task Progress</div>
      {!taskList?.length && <div className={classes.wrapper} style={{ background: getColorByStatus(task?.status)}}>
        <div className={classes.container}>
          <span>
            <span className={classes.title}>Description: </span> 
            {task?.description}
          </span>
          <span>
            <span className={classes.title || "-"}>Number: </span>
            {task?.number}
          </span>
        </div>
        <div className={classes.container}>
          <span>
            <span className={classes.title}>Status: </span>
            <span>{`${task?.status}`}</span>
          </span>
          <span>
            <span className={classes.title}>Progress: </span> 
            {`${task?.progressNow}/${task?.progressMax}`}
          </span>
          <span>
            <span className={classes.title}>TotalTasksInQueue: </span>
            {totalTasksInQueue}
          </span>
        </div>
        <div className={classes.container}>
          <span>
            <span className={classes.title || "-"}>Content Commit: </span>
            {task?.nftContentSha}
          </span>
          <span>
            <span className={classes.title || "-"}>CreatedAt: </span>
            {moment(task?.createDate).format("YYYY-MM-DD-HH:mm")}
          </span>
        </div>
        <div className={classes.container}>
          <span>
            <span className={classes.title || "-"}>Error: </span>
            {task?.error}
          </span>
        </div>
      </div>}
      {!taskList?.length && <Button
        onClick={() => getTaskList({ variables: {
          offset: task?.number,
          limit: 10,
        }})}
        size="small" 
        variant="outlined"
        sx={{m: "0 auto", display: "block"}}
      >
          show history
      </Button>}
      {taskList?.sort((a,b) => b.number - a.number).map((item: ITask, i: number) => (<div key={i} className={classes.wrapper} style={{ background: getColorByStatus(item?.status)}}>
        <div className={classes.container}>
          <span>
            <span className={classes.title}>Description: </span> 
            {item?.description}
          </span>
        </div>
        <div className={classes.container}>
          <span>
            <span className={classes.title}>Status: </span>
            <span>{item?.status}</span>
          </span>
          <span>
            <span className={classes.title}>Progress: </span> 
            {`${item?.progressNow}/${item?.progressMax}`}
          </span>
        </div>
        <div className={classes.container}>
          <span>
            <span className={classes.title || "-"}>Content Commit: </span>
            {task?.nftContentSha}
          </span>
          <span>
            <span className={classes.title || "-"}>CreatedAt: </span>
            {moment(item?.createDate).format("YYYY-MM-DD-HH:mm")}
          </span>
        </div>
        <div className={classes.container}>
          <span>
            <span className={classes.title || "-"}>Error: </span>
            {item?.error}
          </span>
          <span>
            <span className={classes.title || "-"}>Number: </span>
            {item?.number}
          </span>
        </div>
      </div>))}
      {taskList?.length && <Pagination
        sx={{ justifyContent: "center", display: "flex"}} 
        count={task && Math.ceil(task?.number / 11)}
        onChange={handleChange} 
        page={page}
      />}
    </div>
  );
};