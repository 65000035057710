import { gql } from "@apollo/client";

export const unbanUserMutation = gql`
    mutation unbanUser(
        $userId: String!,
    ) {
        unbanUser(
            input: {
                userID: $userId,
            }
        ) {
            ok
        }
    }
`;
