import { useQuery } from "@apollo/client";
import { queryGetWeaponConfig } from "./queryGetWeaponConfig.graphql";
import {
  GetWeaponConfig,
  GetWeaponConfigVariables,
} from "./__generated__/GetWeaponConfig";

export const useGetWeaponConfig = (id: string) => {
  const { data, loading, error, refetch } = useQuery<
    GetWeaponConfig,
    GetWeaponConfigVariables
  >(queryGetWeaponConfig, {
    variables: {
      input: {
        id,
      },
    },
    fetchPolicy: "no-cache",
  });

  return { config: data, loading, error, refetch };
};
