import { useState } from "react";
import TextField from "@mui/material/TextField";
import CircularProgress from "@mui/material/CircularProgress";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { REGENERATE_TOKENS, UPDATE_PRESET, DELETE_PRESET } from "mutations/cgsCollections";
import { useStyles } from "pages/CgsServise/Cgs.style";
import { useMutation } from "@apollo/client";
import { IAttribute, IAttrValue, IPresetProps, IPreset } from "./Cgs.types";
import LockIcon from "@mui/icons-material/Lock";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import { Accordion, AccordionSummary, AccordionDetails} from "./Accordion";
import { PresetHeader } from "./PresetHeader";
import { CustomPopover } from "./components/CustomPopover";
import { TableContainer, Table, TableBody, TableRow, TableCell, Paper } from "@mui/material";


export const Preset = ({ handlePresetEditClick, collection, refetchPresets, presets, presetData, getPreset, isLoading }: IPresetProps) => {

  const classes = useStyles();
  const [expanded, setExpanded] = useState<string | boolean>("");
  const [activePreset, setActivePreset] = useState<IPreset | null | undefined>(null);

  const [anchorEl, setAnchorEl] = useState<SVGSVGElement | null>(null);

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick = (event: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
    setAnchorEl(event.currentTarget);
  };

  const [regenerateTokens] = useMutation(REGENERATE_TOKENS);
  const [deleteCgsPreset] = useMutation(DELETE_PRESET);

  const generateTokens = (collectionID: string, presetID?: string) => {
    regenerateTokens({
      variables: {
        collectionID,
        presetID,
      },
    }).then(({data}) => {
      if(data?.cgsAdminRegenerateTokens?.errors?.length) alert(data.cgsAdminRegenerateTokens.errors[0]);
      else alert("successfull");
    })
      .catch((e) => alert(e.message));
  };

  const [updateCgsPreset, { loading: updateLoading }] = useMutation(
    UPDATE_PRESET,
    {
      variables: activePreset
    }
  );

  const updatePresetValues = () => {
    updateCgsPreset().then(() => {
      refetchPresets({ variables: { id: collection.id} });
      setActivePreset(null);
    }).catch((e: Error) => alert(e.message));
  };

  const updateActivePreset = (value: number, name: string, isWeight?: boolean) => {
    let editedAttrs;

    if(isWeight) {
      editedAttrs = (activePreset?.attributes as IAttribute[])
        .map((attr: IAttribute) => ({ ...attr, values: attr.values
          .map((item: IAttrValue) => {
            return item.name === name ? {...item, weight: value} : item;})}));
    } else {
      editedAttrs = activePreset?.attributes?.map((attr: IAttribute) => attr.name === name ? {...attr, probability: value} : attr);
    }

    setActivePreset({...activePreset, attributes: editedAttrs});
  };

  return (
    <>
      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableBody>
            {presets?.map((preset: IPreset, i: number) => <Accordion key={i} TransitionProps={{ unmountOnExit: true }} expanded={expanded === `${i}`} onChange={handleChange(`${i}`)}>
              <AccordionSummary
                onClick={() => getPreset({ variables: {
                  id: collection.id,
                  presetID: preset.id
                } })}
                aria-controls="panel1d-content" 
                id="panel1d-header"
              >
                <TableRow>
                  <TableCell sx={{ minWidth: 330 }}>{preset.id}</TableCell>
                  <TableCell sx={{ minWidth: 250 }}>{preset.name}</TableCell>
                  <TableCell sx={{ minWidth: 150 }}>
                    <span className={classes.title}>Token Amount:</span>
                    {preset.tokenAmount}
                  </TableCell>
                  <TableCell sx={{ minWidth: 270 }} align="right">
                    <span className={classes.title}>Current Token Amount:</span> 
                    {preset.currentTokenAmount}
                  </TableCell>
                </TableRow>
              </AccordionSummary>
              <AccordionDetails>
                {isLoading ? <div style={{display: "flex", justifyContent: "center" }}><CircularProgress /></div> :
                  <>
                    <DeleteIcon
                      style={{ cursor: "pointer", marginRight: "30px" }}
                      onClick={(e) => handleClick(e)}
                    />
                    <CustomPopover
                      title="Delete"
                      onClick={() => deleteCgsPreset({ variables: { id: preset.id, collectionID: collection.id } })
                        .then(() => refetchPresets({ variables: { id: collection.id} }))
                        .catch(((e: Error) => alert(e.message)))
                        .finally(() => handleClose())
                      } 
                      handleClose={handleClose}
                      anchorEl={anchorEl}
                    />
                    <div className={classes.presetContainer}>
                      <EditIcon className={classes.editIcon} onClick={() => handlePresetEditClick(collection)} />
                      <PresetHeader 
                        collectionID={collection.id}
                        presetID={preset.id as string} 
                        isLocked={presetData?.generationLock}
                      />
                      {!activePreset ? <LockIcon style={{ cursor: "pointer" }} onClick={() => setActivePreset(presetData)} /> :
                        <LockOpenIcon style={{ cursor: "pointer" }} onClick={() => updatePresetValues()} />}
                      <button
                        className={classes.regenerateBtn}
                        onClick={() => {
                          generateTokens(collection.id, preset?.id);
                          refetchPresets({ variables: { id: collection.id} });
                        }}
                        disabled={updateLoading}
                      >regenerate tokens</button>
                      <div className={classes.textContainer}>
                        <span className={classes.title}>Name:</span>
                        {presetData?.name}
                      </div>
                      <div className={classes.textContainer}>
                        <span className={classes.title}>BaseRarity:</span>
                        {presetData?.baseRarity}
                      </div>
                      <div className={classes.textContainer}>
                        <span className={classes.title}>MinMintLevel:</span>
                        {presetData?.minMintLevel}
                      </div>
                      <div className={classes.textContainer}>
                        <span className={classes.title}>IconTokenID:</span>
                        {presetData?.iconTokenID}
                      </div>
                      <div className={classes.textContainer}>
                        <span className={classes.title}>iconsAttempt:</span>
                        {presetData?.iconsAttempt}
                      </div>
                      <div className={classes.textContainer}>
                        <span className={classes.title}>Background:</span>
                        {presetData?.background}
                      </div>
  Attributes: 
                      {presetData?.attributes?.map((attr: IAttribute, j: number) => (
                        <div className={classes.attrContainer} key={j}>
                          <div className={classes.attrHeader}>
                            <div className={classes.textContainer}>
                              <span className={classes.title}>Name:</span>
                              {attr.name}
                            </div>
                            <div className={classes.textContainer}>
                              <TextField
                                id="outlined-basic"
                                label="probability"
                                variant="outlined"
                                disabled={!activePreset}
                                defaultValue={attr.probability || 0}
                                style={{ width: "100px" }}
                                onChange={(e) => updateActivePreset(+e.target.value, attr.name)}
                              />
                            </div>
                          </div>
      Values:
                          {attr?.values?.map((value: IAttrValue, k: number) => (
                            <div key={k} style={{display: "flex", alignItems: "center", justifyContent: "space-between"}}>
                              <div className={classes.textContainer}>
                                <span className={classes.title}>Name:</span>
                                {value.name}
                              </div>
                              <div className={classes.textContainer}>
                                <TextField
                                  id="outlined-basic"
                                  label="weight"
                                  variant="outlined"
                                  disabled={!activePreset}
                                  defaultValue={value.weight || 0}
                                  style={{ width: "70px" }}
                                  onChange={(e) => updateActivePreset(+e.target.value, value.name, true)}
                                />
                              </div>
                            </div>
                          ))}
                        </div>
                      ))}
                    </div>
                  </>
                }
              </AccordionDetails>
            </Accordion>)}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};