export function mapEnum<Enum extends Record<string, unknown>, Ret>(
  obj: Enum,
  callback: (key: keyof Enum, value: Enum[keyof Enum]) => Ret
): Ret[] {
  const result: Ret[] = [];

  for (const [key, value] of Object.entries(obj)) {
    if (String(+key) === key && typeof value !== "number") {
      continue;
    }

    result.push(callback(key, value as Enum[keyof Enum]));
  }

  return result;
}
