import React from "react";
import {DialogTitleWithCross} from "components/DialogTitleWithCross";
import {Dialog, DialogContent, Grid, Typography} from "@mui/material";
import {
  MajorSendField
} from "./components/MajorSendField";
import {
  MinorSendField
} from "./components/MinorSendField";
import {ItemsTabs} from "pages/UserProfile/components/TopActions/components/RewardsModal/components/ItemsTabs";
import {useMutation} from "@apollo/client";
import {rewardPushMutation} from "pages/UserProfile/mutations/rewardPush.graphql";
import {queryGetUser} from "pages/UserProfile/query/getUser.graphql";
import {useUserProfileContext} from "pages/UserProfile/context";
import { v4 as uuidv4 } from "uuid";
import {gameAccountSetExperienceMutation} from "pages/UserProfile/mutations/gameAccountSetExperience.graphql";

interface SendRewardsProps {
  onClose: () => void;
}


const useGetSendActions = () => {
  const {data} = useUserProfileContext();
  const [rewardPush] = useMutation(
    rewardPushMutation,
    {
      refetchQueries: [{ query: queryGetUser, variables: {id: data.userId} }],
    }
  );

  const [updateUser] = useMutation(gameAccountSetExperienceMutation,  {refetchQueries: [{ query: queryGetUser, variables: {id: data.userId} }],});


  const getDefaultVariables = () => ({
    userId: data.userId,
    reasonId: uuidv4(),
  });

  const sendExp = (value: number) => {
    return updateUser({
      variables: {
        userId: data.userId,
        experience: data.overview.gameAccount.xp + value,
      },
    });
  };

  const sendBP = (value: number) => {
    return rewardPush({
      variables: {
        ...getDefaultVariables(),
        rewards: [
          {
            season: {
              points: value
            }
          }
        ]
      },
    });
  };

  const sendOrbs = (value: number) => {
    return rewardPush({
      variables: {
        ...getDefaultVariables(),
        rewards: [
          {
            currency: {
              orbs: value
            }
          }
        ]
      },
    });
  };

  const sendEnrichedOrbs = (value: number) => {
    return rewardPush({
      variables: {
        ...getDefaultVariables(),
        rewards: [
          {
            currency: {
              enrichedOrbs: value
            }
          }
        ]
      },
    });
  };
  const sendButter = (value: number) => {
    return rewardPush({
      variables: {
        ...getDefaultVariables(),
        rewards: [
          {
            currency: {
              butter: value
            }
          }
        ]
      },
    });
  };

  const sendItem = (value: string) => {
    return rewardPush({
      variables: {
        ...getDefaultVariables(),
        rewards: [
          {
            token: {
              items: [
                {configID: value, level: 1, id: uuidv4()}
              ]
            }
          }
        ]
      },
    });
  };

  return {
    sendButter, sendBP,sendOrbs, sendEnrichedOrbs, sendItem, sendExp
  };

};


export const RewardsModal = ({onClose}: SendRewardsProps) => {
  const {data, holderBalances} = useUserProfileContext();


  const {sendEnrichedOrbs, sendButter, sendBP, sendOrbs, sendItem, sendExp} = useGetSendActions();
  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open
      onClose={onClose}
    >
      <DialogTitleWithCross onClose={onClose}>Send Rewards</DialogTitleWithCross>
      <DialogContent>
        <Grid container spacing={2}>
          <MajorSendField inputLabel="Add XP" currentValue={data.overview.gameAccount.xp} sendReward={sendExp} label={<>Current experience <Typography color="black" component="span">(Level: {data.overview.gameAccount.level})</Typography></>}/>
          {/*<MajorSendField inputLabel="Add BP Points"  currentValue={43124312} sendReward={sendBP} label={<>Battle Pass points <Typography color="black" component="span">(Tier: 15)</Typography></>}/>*/}
          <MinorSendField sendReward={sendOrbs} label="Orbs" currentValue={holderBalances?.orb?.amount} />
          <MinorSendField sendReward={sendEnrichedOrbs} label="Enriched Orbs" currentValue={holderBalances?.enrichedOrb?.amount}/>
          <MinorSendField sendReward={sendButter} label="Butter" currentValue={holderBalances?.butter?.amount}/>
          <ItemsTabs onSend={sendItem} />
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

