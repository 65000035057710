import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles({
  dropZoneContainer: {
    backgroundColor: "white",
    display: "flex",
    height: "80px",
    boxSizing: "border-box",
    cursor: "cell",
    border: "1px dashed",
    marginBottom: "25px",
    alignItems: "center",
    justifyContent: "center",
  },
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginRight: "20px",
  },
  wrapper: {
    margin: "15px",
    display: "flex",
  },
  saveToFileBtn: {
    alignSelf: "flex-end",
    display: "flex",
  },
  flex1: {
    flex: "1 1 auto",
  },
});
