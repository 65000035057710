import { useMutation } from "@apollo/client";
import { WeaponConfigCreateQGL } from "./weaponConfigCreate.graphql";
import {
  WeaponConfigCreateVariables,
  WeaponConfigCreate_weaponConfigCreate,
} from "./__generated__/WeaponConfigCreate";

export const useWeaponConfigCreate = () => {
  const [weaponConfigCreate, { loading, error }] = useMutation<
    WeaponConfigCreate_weaponConfigCreate,
    WeaponConfigCreateVariables
  >(WeaponConfigCreateQGL);

  return {
    weaponConfigCreate,
    loading,
    error,
  };
};
