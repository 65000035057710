import React from "react";

type IProps = {
  children?: React.ReactNode;
  w?: React.CSSProperties["width"];
  h?: React.CSSProperties["height"];
  alignItems?: React.CSSProperties["alignItems"];
  alignSelf?: React.CSSProperties["alignSelf"];
  justifyContent?: React.CSSProperties["justifyContent"];
  justifySelf?: React.CSSProperties["justifySelf"];
  gap?: React.CSSProperties["gap"];
  rowGap?: React.CSSProperties["rowGap"];
  columnGap?: React.CSSProperties["columnGap"];
  flex?: React.CSSProperties["flex"];
  flexDirection?: React.CSSProperties["flexDirection"];
  mt?: React.CSSProperties["marginTop"];
  mb?: React.CSSProperties["marginBottom"];
  ml?: React.CSSProperties["marginLeft"];
  mr?: React.CSSProperties["marginRight"];
  display?: React.CSSProperties["display"];
  block?: boolean;
  visible?: boolean;
  position?: React.CSSProperties["position"];
  pointer?: boolean;
  onClick?: any;
  fullWidth?: boolean;
  column?: boolean;
  center?: boolean;
  fontSize?: number;
  color?: string;
  boxRef?: any;
  minW?: number;
  minH?: number;
};
export const Box = React.memo(function Box(props: IProps): JSX.Element {
  const css = {
    ...(props.display
      ? { display: props.display }
      : props.block
        ? { display: "block" }
        : { display: "flex" }),
    ...(props.alignItems ? { alignItems: props.alignItems } : {}),
    ...(props.alignSelf ? { alignSelf: props.alignSelf } : {}),
    ...(props.justifyContent ? { justifyContent: props.justifyContent } : {}),
    ...(props.justifySelf ? { justifySelf: props.justifySelf } : {}),
    ...(props.gap ? { gap: props.gap } : {}),
    ...(props.rowGap ? { rowGap: props.rowGap } : {}),
    ...(props.columnGap ? { columnGap: props.columnGap } : {}),
    ...(props.flex ? { flex: props.flex } : {}),
    ...(props.flexDirection ? { flexDirection: props.flexDirection } : {}),
    ...(props.mt ? { marginTop: props.mt } : {}),
    ...(props.mb ? { marginBottom: props.mb } : {}),
    ...(props.ml ? { marginLeft: props.ml } : {}),
    ...(props.mr ? { marginRight: props.mr } : {}),
    ...(props.w ? { width: props.w } : {}),
    ...(props.h ? { height: props.h } : {}),
    ...(props.visible === undefined
      ? {}
      : { visibility: props.visible ? "visible" : "hidden" }),
    ...(props.position ? { position: props.position } : {}),
    ...(props.pointer ? { cursor: "pointer" } : {}),
    ...(props.fullWidth ? { width: "100%" } : {}),
    ...(props.column ? { flexDirection: "column" } : {}),
    ...(props.center ? { alignItems: "center", justifyContent: "center" } : {}),
    ...(props.fontSize ? { fontSize: props.fontSize } : {}),
    ...(props.color ? { color: props.color } : {}),
    ...(props.minW ? { minWidth: props.minW } : {}),
    ...(props.minH ? { minHeight: props.minH } : {}),
  };
  return (
    //@ts-ignore
    <div ref={props.boxRef ?? undefined} onClick={props.onClick} style={css}>
      {props.children}
    </div>
  );
});
