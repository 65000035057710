import React, { ChangeEvent, useCallback, useEffect, useState } from "react";
import { useLazyQuery, useMutation } from "@apollo/client";
import TextField from "@mui/material/TextField";
import s from "./RewardUser.module.css";
import { GET_ALL_USERS } from "query/users";
import {
  Alert,
  Autocomplete,
  Button,
  FormControl,
  Select,
} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import Box from "@mui/material/Box";
import { ADD_GAMEDATA_REWARD, ADD_MINTABLE_REWARD } from "mutations/addRewar";
import { v4 as uuidv4 } from "uuid";
import { GetUsers_lookup_accounts_search_items } from "components/SearchBar/query/__generated__/GetUsers";
import { debounce } from "lodash";
import { GET_GAMEDATA_ITEMS, GET_MINTABLE_ITEMS } from "query/getConfigItem";

type IRewardType = "gamedata" | "generator";
const width: React.CSSProperties = { width: "300px", boxSizing: "border-box" };

export const RewardUser = () => {
  const [userInput, setUserInput] = useState("");
  const [suffixSearch, setSuffix] = useState("");
  const [toggle, setToggle] = useState(false);
  const [users, setUsers] = useState<GetUsers_lookup_accounts_search_items[]>([]);
  const [currentUser, setCurrent] = useState<GetUsers_lookup_accounts_search_items | null>(null);
  const [showSearchResults, setShowSearchResults] = useState(false);
  const [rewardType, setRewardType] = useState<IRewardType>("gamedata");
  const [itemInput, setItemInput] = useState("");
  const [showResult, setShowResult] = useState(false);
  const [searchUsers, { data: usersResult, error: eUser }] = useLazyQuery(
    GET_ALL_USERS,
    {
      variables: {
        username: userInput,
        suffix: suffixSearch,
      },
    }
  );
  const runSearchUsers = useCallback(
    debounce(() => searchUsers(), 1000),
    []
  );

  const [searchMintable, { data: mintables }] = useLazyQuery(
    GET_MINTABLE_ITEMS,
    {
      variables: {
        query: itemInput,
      },
    }
  );
  const runSearchMintable = useCallback(
    debounce(() => searchMintable(), 500),
    []
  );
  const mintableItemOptions = mintables
    ? mintables.lookup.itemConfigs.resolve.map((i: any) => i.configID)
    : [];

  const [searchGamedata, { data: gamedataItems }] = useLazyQuery(
    GET_GAMEDATA_ITEMS,
    {
      variables: {
        query: itemInput,
      },
    }
  );
  const runSearchGamedata = useCallback(
    debounce(() => searchGamedata(), 500),
    []
  );
  const gamedataItemOptions = gamedataItems
    ? gamedataItems.lookup.itemConfigs.resolve.map((i: any) => ({
      label: i.name + " " + i.itemID,
      id: i.itemID,
      key: i.itemID,
    }))
    : [];

  const [addGameDataReward, { data: d1, loading: l1, error: e1 }] = useMutation(
    ADD_GAMEDATA_REWARD,
    {
      variables: {
        userId: currentUser?.userId,
        reasonId: uuidv4(),
        fromDesc: "added by admin",
        itemID: itemInput,
      },
    }
  );

  const [addMintableReward, { data: d2, loading: l2, error: e2 }] = useMutation(
    ADD_MINTABLE_REWARD,
    {
      variables: {
        userId: currentUser?.userId,
        reasonId: uuidv4(),
        fromDesc: "added by admin",
        configID: itemInput,
      },
    }
  );

  useEffect(() => {
    if (usersResult) {
      setUsers(usersResult?.lookup?.accounts?.search?.items);
    } else {
      setUsers([]);
    }
  }, [usersResult]);

  const handleUserInput = (value: string) => {
    setShowResult(false);
    if (toggle) {
      setSuffix(value);
    } else {
      setUserInput(value);
      setCurrent(null);
      setShowSearchResults(true);
      runSearchUsers();
    }
  };

  const handleMintInputChange = (val: string) => {
    setItemInput(val);
    setShowResult(false);
    runSearchMintable();
  };

  const handleGamedataInputChange = (val: string) => {
    setItemInput(val);
    runSearchGamedata();
    setShowResult(false);
  };

  const handleSend = () => {
    rewardType === "gamedata" ? addGameDataReward() : addMintableReward();
    setShowResult(true);
  };

  const loading = l1 || l2;
  const error = rewardType === "gamedata" ? e1 : e2;
  const mutationData = rewardType === "gamedata" ? d1 : d2;

  console.log(mintableItemOptions);

  if (eUser) return <div>unauthenticated</div>;
  return (
    <Box display="flex" flexDirection="row">
      <Box
        display="flex"
        flexDirection="column"
        rowGap={2}
        m={5}
        flex="0 0 300px"
        position="relative"
      >
        <TextField
          id="outlined"
          value={userInput}
          label="User Name"
          variant="outlined"
          style={width}
          fullWidth
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            handleUserInput(e.target.value)
          }
        />
        <div className={s.userList}>
          {showSearchResults &&
            users.map((item: GetUsers_lookup_accounts_search_items, i: number) => (
              <div
                onClick={() => {
                  setUserInput(
                    item?.ident.username + " #" + item?.ident.suffix || ""
                  );
                  setCurrent(item);
                  setShowSearchResults(false);
                }}
                className={s.userItem}
                key={i}
              >
                {item?.ident?.username}
                <span>{item?.ident?.suffix}</span>
              </div>
            ))}
        </div>
        <FormControl fullWidth>
          <InputLabel id="rewardType">Type of Reward</InputLabel>
          <Select
            style={width}
            labelId="rewardType"
            id="demo-simple-select"
            value={rewardType}
            label="Type of Reward"
            onChange={(e) => {
              setItemInput("");
              setShowResult(false);
              setRewardType(e.target.value as IRewardType);
            }}
          >
            <MenuItem value={"gamedata"}>gamedata</MenuItem>
            <MenuItem value={"generator"}>generator</MenuItem>
          </Select>
        </FormControl>
        {rewardType === "generator" && (
          <Autocomplete
            disablePortal
            autoHighlight
            options={mintableItemOptions}
            fullWidth
            renderInput={(params) => <TextField {...params} label="ConfigId" />}
            onInputChange={(e, value) => handleMintInputChange(value)}
            value={itemInput}
          />
        )}
        {rewardType === "gamedata" && (
          <Autocomplete
            disablePortal
            options={gamedataItemOptions}
            fullWidth
            autoHighlight
            renderInput={(params) => <TextField {...params} label="ItemId" />}
            onChange={(event: any, newValue: any) => {
              console.log(newValue);
              setItemInput(newValue.id);
            }}
            onInputChange={(e, value) => handleGamedataInputChange(value)}
            value={itemInput}
            // onSelect={(a,b,c)=>{console.log(a,b,c);}}
          />
        )}

        <Button
          variant="contained"
          style={{ width: "30px" }}
          onClick={handleSend}
          disabled={!currentUser || !itemInput || loading}
        >
          Send
        </Button>
        {showResult && error && (
          <Alert severity="error" style={width}>
            Ошибка, посмотрите вкладку network в консоли
          </Alert>
        )}
        {showResult && mutationData && (
          <Alert severity="success" style={width}>
            Предмет успешно добавлен
          </Alert>
        )}
      </Box>
      <Box flex="1 1 400px">
        <ul>
          mintable example
          <br />
          <br />
          <li>MK18-Siberian_Dragon</li>
          <li>Steyr-SSG-69-Winterlight</li>
          <li>UZI-Camo_Party</li>
          <li>P90-Sea_Dragon</li>
          <li>AK47-Repeat_Offender</li>
          <li>AK47-Primrose</li>
          <li>AK47-City_Lights</li>
          <li>KG9-Natasha</li>
          <li>KG9-Sea_Fury</li>
          <li>KG9-Toxic_Fury</li>
          <li>UZI-Cold_Metal</li>
          <li>UZI-Fuchsia</li>
          <li>UZI-Grape_Soda</li>
          <li>MP5-Plan_B</li>
        </ul>
        <br />
        <ul>
          gamedata example
          <br />
          <br />
          <li>f8b2d969-b35d-4d02-982a-377efe2a0c51</li>
          <li>be97e8e2-b361-47ef-9757-b449fd3091e5</li>
          <li>f36a4684-4180-4ed1-95c1-676259d63b12</li>
          <li>ed28608b-35f6-4313-b794-760b5c5a67d2</li>
        </ul>
      </Box>
    </Box>
  );
};
