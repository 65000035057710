import { Typography } from "@mui/material";
import { PerkWrapperStyled } from "./Perk/styled";
import { FormikHandlers } from "formik";
import { IPerk } from "pages/Weapons/FormWeapon/types";
import { PerkItem } from "./Perk/PerkItem";

export const Conditions: React.FC<{
  perks: IPerk[];
  handleChange: FormikHandlers["handleChange"];
}> = ({ perks, handleChange }) => {
  return (
    <PerkWrapperStyled>
      <Typography variant="body2" mb={2}>
        Conditions
      </Typography>

      {perks.map((perk, perkItemIndex) => {
        return (
          <PerkItem
            perk={perk}
            key={perk.name}
            name={`conditions.${perkItemIndex}`}
            handleChange={handleChange}
          />
        );
      })}
    </PerkWrapperStyled>
  );
};
