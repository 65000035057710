import { gql } from "@apollo/client";

export const CREATE_EVENT = gql`
    mutation CreateNewEventTest($input: ClanEventCreateInput!) {
  clanEventCreateEvent(input: $input) {
    __typename
    ... on ClanEvent {
      id
      status
      name
      startAt
      endAt
      visibleAfter
      raidDurationSeconds
      raidMinRewardPercent
      raidLevels {
        difficulties {
          difficulty
          questID
          rewardPoints
        }
        price
      }
      rewards {
        placeFromInclusive
        placeToExclusive
        reward {
          __typename
          ... on ClanEventRewardMoney {
            amount
          }
          ... on ClanEventRewardInventory {
            itemID
            amount
          }
          ... on ClanEventRewardToken {
            configID
            level
            amount
          }
        }
      }
      missionProgressBoosts {
        nftAttributes {
          title
          description
          specs {
            title
            description
            percent
            attribute {
              name
              value
            }
          }
        }
      }
    }
    ... on ValidationErrors {
      errors {
        field
        message
      }
    }
  }
}
`;

export const UPDATE_EVENT = gql`
   mutation UpdateNewEventTest($input: ClanEventUpdateInput!) {
  clanEventUpdateEvent(input: $input) {
    __typename
    ... on ClanEvent {
      id
      raidLevels {
        difficulties {
          difficulty
          questID
          rewardPoints
        }
        price
      }
      rewards {
        placeFromInclusive
        placeToExclusive
        reward {
          __typename
          ... on ClanEventRewardMoney {
            amount
          }
          ... on ClanEventRewardInventory {
            itemID
            amount
          }
          ... on ClanEventRewardToken {
            configID
            level
            amount
          }
        }
      }
      missionProgressBoosts {
        nftAttributes {
          title
          description
          specs {
            title
            description
            percent
            attribute {
              name
              value
            }
          }
        }
      }
    }
    ... on ValidationErrors {
      errors {
        field
        message
      }
    }
  }
}
`;

export const CREATE_YML_QUEST = gql`
   mutation createQuestYml($file: String! ) {
    questsSubmitConfigYaml(
        input: {
        file: $file
        }
    ) {
    __typename
          ... on GenericResult {
            ok
          }
          ... on ValidationErrors {
            errors {
              field
              message
            }
          }
  }
}
`;

