import { Identity } from "@mui/base";
import {
  Box,
  Card,
  CardActions,
  CardContent,
  Typography,
  Button,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { string } from "yup";

interface WeaponCardProps {
  id: string;
  deployWeapons: (variables: any ) => Promise<any>;
  season: string;
}

export const WeaponCard = ({ id, deployWeapons, season }: WeaponCardProps) => {
  const navigate = useNavigate();

  return (
    <Box width="25%" py={1}>
      <Box px={2}>
        <Card variant="outlined">
          <CardContent>
            <Typography variant="body1" component="div">
              {id}
            </Typography>
          </CardContent>
          <CardActions sx={{display: "flex", justifyContent: "space-between"}}>
            <Button size="small" onClick={() => navigate(id)}>
              Edit config
            </Button>
            <Button size="small" variant="outlined" onClick={() => deployWeapons({variables: { weaponId: id }}).then(() => alert("successfull")).catch((e: Error) => alert(e.message))}>
              Deploy
            </Button>
          </CardActions>
        </Card>
      </Box>
    </Box>
  );
};
