import { gql } from "@apollo/client";

export const REVERT_LOG = gql`
  mutation RevertLog($logID: String!, $batch: Boolean! ) {
    cgsAdminRevertByLog(
        input: {
        logID: $logID
        batch: $batch
        }
    ) {
  		ok
        }
}
`;

