import { useState, useCallback } from "react";
import { useMutation } from "@apollo/client";
import { useDropzone } from "react-dropzone";
import { useStyles } from "pages/Events/ClanEvents.style";
import { UPDATE_EVENT, CREATE_YML_QUEST } from "mutations/event";
import Button from "@mui/material/Button";
// eslint-disable-next-line @typescript-eslint/no-var-requires
const Buffer = require("buffer/").Buffer;

export const UpdateClanEvents = ({ icon }: any) => {
  const [File, setJson] = useState(null as any);

  const classes = useStyles(!!icon);

  const [sendFile, { data, error }] = useMutation(UPDATE_EVENT, {
    variables: {
      input: File,
    },
  });
  console.log(File, "jsonFile");

  const onDrop = useCallback((acceptedFiles: File[]) => {
    const reader = new FileReader();

    reader.onload = function (e) {
      ///from yml to string
      if (acceptedFiles[0].type !== "application/json") {
        const ymlString = Buffer.from(
          e.target?.result as string,
          "base64"
        ).toString("ascii");
        setJson(ymlString);
      } else {
        fetch(e.target?.result as string)
          .then((response) => response.json())
          .then((data) =>
            setJson({
              eventID: data?.id,
              event: {
                name: data?.name,
                bannerActiveUrl: data?.bannerActiveUrl,
                bannerInactiveUrl: data?.bannerInactiveUrl,
                visibleAfter: data?.visibleAfter,
                raidLevels: data?.raidLevels,
                rewards: data?.rewards,
                individualRewards: data?.individualRewards,
                missionProgressBoosts: data?.missionProgressBoosts,
              },
              worldDomination: data?.worldDomination,
            })
          );
      }
    };
    reader.readAsDataURL(acceptedFiles[0]);
  }, []);

  const { getRootProps, getInputProps } = useDropzone({ onDrop });
  return (
    <div className={classes.container}>
      <p>Update Event</p>
      <div {...getRootProps()} className={classes.dropZoneContainer}>
        <input {...getInputProps()} />
        <p>Drag n drop files here, or click to select files</p>
      </div>
      <div style={{ fontSize: "20px", marginBottom: "10px" }}>
        {(data?.clanEventUpdateEvent?.errors?.length &&
          data?.clanEventUpdateEvent?.errors.map(
            (item: any) => item.message
          )) ||
          error?.message ||
          (data?.clanEventUpdateEvent?.id && "success")}
      </div>
      <Button variant="contained" onClick={() => sendFile()}>
        UPDATE
      </Button>
    </div>
  );
};
