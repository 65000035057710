import React from "react";
import {Button, Grid, Typography} from "@mui/material";
import {TextField} from "components/formik/TextField";
import {DatePicker} from "components/formik/DatePicker";
import {useFormik, FormikProvider} from "formik";
import {useUserProfileContext} from "pages/UserProfile/context";
import {toast} from "react-toastify";
import {useMutation} from "@apollo/client";
import {updateUserMutation} from "../../../../../../mutations/updateUser.graphql";
import {getToastPromiseConfig} from "utils/toast";
import {queryGetUser} from "pages/UserProfile/query/getUser.graphql";

interface FormFields {
  username: string;
  suffix: string;
  verifiedAt: string;
  email: string;
}

interface AccountFormProps {
  onSuccess: () => void;
}

export const AccountForm = ({onSuccess}: AccountFormProps) => {
  const {data} = useUserProfileContext();
  const [updateUser] = useMutation(updateUserMutation,  {refetchQueries: [{ query: queryGetUser, variables: {id: data.userId} }],});
  const handleSubmit = async (values: FormFields) => {
    toast.promise(updateUser({
      variables: {
        userId: data.userId,
        username: values.username,
        suffix: values.suffix,
        email: values.email,
        verifiedAt: values.verifiedAt,
      },
    }), getToastPromiseConfig()).then(onSuccess);
  };

  const formik = useFormik({onSubmit: handleSubmit, initialValues: {
    username: data.ident.username,
    suffix: data.ident.suffix,
    verifiedAt: data.personalInfo.timeline.verifiedAt,
    email: data.personalInfo.email
  }});
  return (
    <FormikProvider value={formik}>
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography fontWeight={700} fontSize={14}>
            ACCOUNT
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <TextField fullWidth name="username" label="Username"/>
          </Grid>
          <Grid item xs={6}>
            <TextField fullWidth name="suffix" label="Suffix"/>
          </Grid>
          <Grid item xs={6}>
            <TextField type="email" fullWidth name="email" label="Email"/>
          </Grid>
          <Grid item xs={6}>
            <DatePicker textFieldProps={{fullWidth: true}} name="verifiedAt" label="Verified at"/>
          </Grid>
          <Grid item container justifyContent="flex-end" spacing={1} mb={2}>
            <Grid item>
              <Button type="submit" color="secondary"  size="large" variant="contained">
                Save account
              </Button>
            </Grid>
          </Grid>
        </Grid>

      </form>
    </FormikProvider>
  );
};
