import { Popover } from "@mui/material";
import { Container } from "./Popover.styles";

interface IpopoverProps {
    anchorEl: Element | null;
    handleClose: () => void;
    onClick: () => void;
    children: JSX.Element;
}

export const StyledPopup = ({ anchorEl, children, handleClose}: IpopoverProps) => {

  return (
    <Popover
      open={!!anchorEl}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
    >
      <Container>
        {children}
      </Container>
    </Popover>
  );
};