import { gql } from "@apollo/client";

// export const getListMintableItemConfigGraphql = gql`
//   query GetListMintableItemConfigGraphql(
//     $query: String!
//     $itemType: ItemType!
//     $limit: Int!
//     $offset: Int!
//   ) {
//     lookup {
//       itemConfigs {
//         resolve(
//           input: {
//             query: $query
//             itemType: $itemType
//             pagination: { limit: $limit, offset: $offset }
//           }
//         ) {
//           __typename
//           ... on MintableItemConfig {
//             configID
//           }
//         }
//       }
//     }
//   }
// `;

export const getListMintableItemConfigGraphql = gql`
  query GetListMintableItemConfigGraphql(
    $query: String
    $limit: Int
    $pageToken: String
    $season: String
  ) {
      lookup {
        weapons {
          search(input: 
          {
           query: $query,
           season: $season,
           pagination: { 
            limit: $limit,
            pageToken: $pageToken,
                 }
          }) {
            configs {
              id
            }
            nextPageToken
          }
        }
      }
  }
`;