import React from "react";
import CircleIcon from "@mui/icons-material/Circle";
import {Typography} from "@mui/material";
import {Wrapper} from "./BaseInfoItem.styles";

type BaseInfoItemProps = {
    label: string;
    value: string;
}

export const BaseInfoItem = ({label, value}: BaseInfoItemProps) => {
  return (
    <Wrapper>
      <CircleIcon sx={{color: "#D9D9D9", mr: 1}}/>
      <div>
        <Typography fontSize={12} color="rgba(119, 119, 119, 1)">
          {label}
        </Typography>
        <Typography fontSize={14} >
          {value}
        </Typography>
      </div>
    </Wrapper>
  );
};
