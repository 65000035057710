import { useMemo, useEffect, useCallback, SetStateAction, Dispatch } from "react";
import Avatar from "@mui/material/Avatar";
import { DropdonwContainer, Filters, StyledButton, ListItem, SearchListContainer, EmptyBlock } from "./SearchBar.styles";
import { GetUsers_lookup_accounts_search_items } from "components/SearchBar/query/__generated__/GetUsers";
import { useNavigate } from "react-router-dom";


interface DropDownMenuProps {
    searchResult?: GetUsers_lookup_accounts_search_items[];
    activeBtn: {[key: string]: boolean};
    currenrtSearch: string;
    loading: boolean;
    setActive: Dispatch<SetStateAction<{[key: string]: boolean}>>;
    setFocusActive: Dispatch<SetStateAction<boolean>>;
    setSearch: Dispatch<SetStateAction<string>>;
}

export const DropDownMenu = ({ searchResult, currenrtSearch, activeBtn, loading, setActive, setSearch, setFocusActive }: DropDownMenuProps) => {

  const nav = useNavigate();

  const onClick = useCallback((event: MouseEvent) => {
    const path = (event as any).path || (event.composedPath && event.composedPath());
    const isInsideClick = path.find((item: Element) => item?.id === "search")?.id;
    if (!isInsideClick) {
      setFocusActive(false);
      setActive({
        username: true,
        discord: false,
        email: false,
      }); 
    }
  }, []);

  useEffect(() => {
    document.addEventListener("click", onClick);
    return () => {
      document.removeEventListener("click", onClick);
    };
  }, []);

  const filtersList = ["username", "discord", "email"];
  ///// delete when all filters will be ready
  const initialButtons = useMemo(() => ({
    username: false,
    discord: false,
    email: false,
  }), []);

  const dataList = currenrtSearch ? searchResult : [];

  return <DropdonwContainer>
    <Filters id="search">
      <div>Player</div>
      <div>
        {filtersList.map((item) => (
          <StyledButton 
            key={item} 
            active={activeBtn[item]}
            // onClick={() => setActive({...activebtn, [item]: !activebtn[item]})} Working select for all filters when they ready
            onClick={() => setActive({...initialButtons, [item]: true})}
          >
            {item}
          </StyledButton>
        ))}
      </div>
    </Filters>
    <SearchListContainer isResult={searchResult?.length}>
      {dataList?.map((item, i) => (<ListItem key={i} onClick={() => {
        setSearch("");
        nav(`users/${item.userId}`);}}>
        <Avatar src={"/"} sx={{ mr: 2, height: "30px", width: "30px"}} />
        {activeBtn.username && `${item.ident.username}#${item.ident.suffix}`}
        {activeBtn.email && `${item.personalInfo.email}`}
        {activeBtn.discord && `${item.overview.discord?.username || "-"}`}
      </ListItem>)
      )}
      {!searchResult?.length && currenrtSearch && <EmptyBlock>{loading ? "Search in progress..." : "Nothing found"}</EmptyBlock>}
    </SearchListContainer>
  </DropdonwContainer>;
};