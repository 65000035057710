import { useMutation } from "@apollo/client";
import { marketNameUpdateGQL } from "./marketNameUpdate.graphql";
import {
  MarketNameUpdateVariables,
  MarketNameUpdate_marketNameUpdate,
} from "./__generated__/MarketNameUpdate";

export const useMarketNameUpdate = () => {
  const [updateMarketName, { loading, error }] = useMutation<
    MarketNameUpdate_marketNameUpdate,
    MarketNameUpdateVariables
  >(marketNameUpdateGQL);

  return {
    updateMarketName,
    loading,
    error,
  };
};
