import React, {useState} from "react";
import {Grid, Box, Typography, Button, CircularProgress} from "@mui/material";
import {GetUser_lookup_accounts_resolve_overview_inventory_items as InventoryItem} from "pages/UserProfile/query/__generated__/GetUser";
import TextField from "@mui/material/TextField";
import {useUserProfileContext} from "pages/UserProfile/context";
import {useMutation} from "@apollo/client";
import {inventoryDeleteAccountItem} from "pages/UserProfile/mutations/inventoryDeleteAccountItem.graphql";
import {sgsSetTokenLevel} from "pages/UserProfile/mutations/sgsSetTokenLevel.graphql";
import {queryGetUser} from "pages/UserProfile/query/getUser.graphql";

type ModalCardProps = {
    item: InventoryItem | null;
    onItemSelect: (newItem: string | null) => void;
}

export const ModalCard = ({item, onItemSelect}: ModalCardProps) => {
  const {data} = useUserProfileContext();
  const [deleteItem, {loading: deleteLoading}] = useMutation(
    inventoryDeleteAccountItem,
    {
      refetchQueries: [{ query: queryGetUser, variables: {id: data.userId} }],
      variables: {
        userId: data.userId,
        itemId: item?.id
      },
    }
  );

  const [setLevel, {loading: setLevelLoading}] = useMutation(
    sgsSetTokenLevel,
    {
      refetchQueries: [{ query: queryGetUser, variables: {id: data.userId}}],
    }
  );
  const handleDelete = () => {
    deleteItem().then(() => onItemSelect(null));
  };


  const [currentLevel, setCurrentLevel] = useState(item?.overview.progress?.level);

  const handleSaveChanges = () => {
    if(currentLevel) {
      setLevel({
        variables: {
          level: currentLevel,
          tokenId: item?.id
        }
      });
    }

  };
  if(deleteLoading || setLevelLoading) {
    return <Grid item xs={6} display="flex" justifyContent="center" alignItems="center"><CircularProgress /></Grid>;
  }
  if(!item) {
    return (
      <Grid container item xs={6} direction="column" justifyContent="center" alignItems="center">
        <Grid item>
          <Typography fontWeight={700} fontSize={22}>
            The item is not selected
          </Typography>
        </Grid>
        <Grid item>
          <Typography fontSize={17} color="rgba(119, 119, 119, 1)">
            Please select the item to edit first
          </Typography>
        </Grid>
      </Grid>
    );
  }
  const reset = () => {
    setCurrentLevel(item?.overview.progress?.level);
  };
  return (
    <Grid container direction="column" justifyContent="space-between" item xs={6}>
      <Grid container direction="column" item spacing={1}>
        <Grid item>
          <Grid container width="100%" sx={{background: "#F4F4F4", borderRadius: 2}} p={2}>
            <Grid item>
              <Box
                mr={3}
                width={120}
                component="img"
                sx={{
                  borderRadius: 1,
                }}
                src={item.overview?.content?.asset?.icon || ""}
              />
            </Grid>
            <Grid item justifyContent="center" display="flex" sx={{flexDirection: "column"}}>
              <Typography fontWeight={700} fontSize={22}>
                {item.overview?.name}
              </Typography>
              <Typography fontSize={16}>
              Level {item.overview?.progress?.level}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Typography width="100%" align="right" color="rgba(119, 119, 119, 1)">
          Current experience {item?.overview.progress?.points} <Typography color="black" component="span">(Level: {item.overview.progress?.level})</Typography>
          </Typography>
        </Grid>
        <Grid item>
          <TextField
            value={currentLevel}
            size="small"
            label="Edit level"
            variant="outlined"
            fullWidth
            onChange={(e) => setCurrentLevel(+e.target.value)}
          />
        </Grid>
        <Grid item>
          <Button
            color="secondary"
            size="small"
            variant="contained"
            onClick={reset}
          >
                Reset
          </Button>
        </Grid>
      </Grid>
      <Grid item container>
        <Grid item xs={4}>
          <Button onClick={handleDelete} size="large" variant="contained" color="secondary">
                Delete item
          </Button>
        </Grid>
        <Grid item container xs={8} justifyContent="flex-end" spacing={2}>
          <Grid item>
            <Button onClick={() => onItemSelect(null)} size="large" variant="contained" color="secondary">
                    Cancel
            </Button>
          </Grid>
          <Grid item>
            <Button onClick={handleSaveChanges} size="large" variant="contained" color="secondary">
                Save changes
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

