import styled from "@emotion/styled";

export const MaterialFieldsStyled = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 12px;
  flex-wrap: wrap;

  > label {
    margin-left: 0;
  }
`;

export const MaterialWrapperStyled = styled.div`
  padding: 16px;
  border: 1px solid #ccc;
  border-radius: 8px;
  margin-bottom: 16px;
`;

export const MaterialNumberStyled = styled.div`
  text-align: end;
  margin-bottom: 12px;
`;
