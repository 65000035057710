import React from "react";

type IProps = Partial<Record<"w" | "h", number>>;

export const Gap = React.memo(function Gap({ w, h }: IProps) {
  const style = {
    ...(w && { marginRight: w }),
    ...(h && { marginBottom: h }),
  };
  return <div style={style} />;
});
