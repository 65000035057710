import { gql } from "@apollo/client";

export const GET_COLLECTION_IDS = gql`
  query CollectionIds{
  lookup {
    cgsAdmin {
      collections {
        id
      }
    }
  }
}
`;

