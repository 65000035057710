import { gql } from "@apollo/client";

export const banUserMutation = gql`
    mutation banUser(
        $userId: UUID!,
        $reason: String!,
        $displayedReason : String!,
        $startsAt: Time!,
        $expiresAt: Time!,
    ) {
        banUser(
            input: {
                userID: $userId,
                reason: $reason,
                expiresAt: $expiresAt,
                displayedReason: $displayedReason,
                startsAt: $startsAt,
            }
        ) {
            ok
        }
    }
`;
