import { gql } from "@apollo/client";

export const queryGetAvailableData = gql`
  query GetAvailableData {
    lookup {
      attributes {
        availableWeaponTypes
        availableDropTypes
        availableSeasons
        availableNameCounters
      }
    }
  }
`;
