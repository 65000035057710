import { Button, FormControlLabel, Switch } from "@mui/material";
import { IWeaponMaterial } from "../../types";
import { TextFieldWrapper } from "../TextFieldWrapper";
import {
  MaterialFieldsStyled,
  MaterialNumberStyled,
  MaterialWrapperStyled,
} from "./styles";

import { FormikHandlers } from "formik";

interface IMaterialProps {
  material: IWeaponMaterial;
  index: number;
  handleChange: FormikHandlers["handleChange"];
  removeMaterial: (index: number) => void;
}

export const Material: React.FC<IMaterialProps> = ({
  material,
  index,
  handleChange,
  removeMaterial,
}) => {
  const materialName = `materials.${index}`;

  return (
    <MaterialWrapperStyled>
      <MaterialNumberStyled>#{index + 1}</MaterialNumberStyled>
      <TextFieldWrapper name={`${materialName}.name`} label="Name" />
      <MaterialFieldsStyled>
        {Object.entries(material).map(([key, value]) => {
          if (typeof value !== "boolean") {
            return null;
          }

          return (
            <FormControlLabel
              control={
                <Switch
                  checked={value}
                  name={`${materialName}.${key}`}
                  onChange={handleChange}
                />
              }
              label={key}
              key={key}
              labelPlacement="start"
            />
          );
        })}
      </MaterialFieldsStyled>
      {index !== 0 && (
        <Button
          variant="outlined"
          size="small"
          onClick={() => removeMaterial(index)}
        >
          Delete
        </Button>
      )}
      {/* <TexturesDropzone name={`${materialName}.textures`} /> */}
      {/* <MaterialJson name={`${materialName}.json`} /> */}
    </MaterialWrapperStyled>
  );
};
