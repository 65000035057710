import { gql } from "@apollo/client";

export const LOGIN = gql`
  mutation googleLogin($accessToken: Password!) {
    googleConnect(input: { accessToken: $accessToken }) {
      email
      fullName
    }
  }
`;
