import { HeaderCard } from "./components/HeaderCard";
import { PlayersTable } from "./components/PlayersTable";

import { Container } from "./Dashboard.styles";

export const Dashboard = () => {
  return (
    <>
      <Container>
        <HeaderCard title="Total players"/>
        <HeaderCard title="Total clans"/>
      </Container>
      <PlayersTable />
    </>
  );
};