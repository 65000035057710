import { useLazyQuery, useQuery } from "@apollo/client";
import { useEffect } from "react";
import { getMarketName } from "./getMarketName.graphql";
import {
  GetMarketName,
  GetMarketNameVariables,
} from "./__generated__/GetMarketName";

export const useGetMarketName = (value?: string) => {
  const [fetchMarketName, { data, loading, error }] = useLazyQuery<
    GetMarketName,
    GetMarketNameVariables
  >(getMarketName);

  useEffect(() => {
    if (value) {
      fetchMarketName({
        variables: { input: { attribute: "WeaponSkin", value: value } },
        fetchPolicy: "no-cache",
      });
    }
  }, [value]);

  return { marketName: data?.lookup.marketName.get, loading, error };
};
