import { gql } from "@apollo/client";

export const GET_IMG_COLLECTIONS = gql`
  query search($id:  UUID) {
    lookup {
    gameImages {
      collections(id: $id){
        collectionID,
        createdAt,
        updatedAt,
        name,
        description,
      }
    }
  	}
  }
`;

export const GET_IMAGES = gql`
  query search($imageID:  UUID, $collectionID: UUID) {
    lookup {
    gameImages {
      images(input: {
        imageID: $imageID,
        collectionID: $collectionID
      }){
        imageID,
        collectionID,
        createdAt,
        updatedAt,
        startDate,
        isBuiltin,
        image,
        redirectURL,
      }
    }
  	}
  }
`;