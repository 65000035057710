import Box from "@mui/material/Box";
import { SelectChangeEvent } from "@mui/material/Select/SelectInput";
import { InputLabel, MenuItem, Select } from "@mui/material";
import { useState } from "react";
import { defaultServer } from "../../constants";
import { useNavigate } from "react-router-dom";

const servers = [
  "https://dev1-server.miniroyale.io/graphql/api",
  "https://staging-server.miniroyale.io/graphql/api",
  "https://prod-server.miniroyale.io/graphql/api",
];

export function Settings() {
  const navigate = useNavigate();
  const [server, setServer] = useState(
    localStorage.getItem("server") || defaultServer
  );
  const handleChange = (e: SelectChangeEvent) => {
    localStorage.setItem("server", e.target.value);
    setServer(e.target.value);
    navigate("/");
    location.reload();
  };
  return (
    <Box width={400} m={3}>
      <InputLabel id="server">Server</InputLabel>
      <Select
        labelId="server"
        id="demo-simple-select"
        value={server}
        label="Server"
        onChange={handleChange}
      >
        {servers.map((item) => (
          <MenuItem key={item} value={item}>
            {item}
          </MenuItem>
        ))}
      </Select>
    </Box>
  );
}
