import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles({
  cgsWrapper: {
    display: "flex",
    padding: "15px",
  },
  buttonsContainer: {
    marginRight: "25px",
    display: "flex",
    flexDirection: "column",
    minWidth: "200px",
  },
  title: {
    color: "grey",
    marginRight: "7px",
  },
  textContainer: {
    padding: "4px",
    marginBottom: "3px",
  },
  fileModal: {
    position: "fixed",
    top: "40px",
    height: "650px",
    overflow: "scroll",
    transform: "translateX(50%)",
    right: "50%",
    width: "1000px",
    backgroundColor: "#fff",
    border: "1px solid black",
    padding: "35px",
  },
  editIcon: {
    position: "absolute",
    right: "20px",
    top: "10px",
    cursor: "pointer",
  },
  deleteIcon: {
    position: "absolute",
    right: "70px",
    top: "10px",
    cursor: "pointer",
  },
  collectionWrapper: {
    minWidth: "900px",
  },
  detailsContainer: {
    border: "2px solid black",
    padding: "10px",
    backgroundColor: "#fafafa",
  },
  presetTitle: {
    color: "green",
    borderBottom: "1px solid grey"
  },
  presetContainer: {
    border: "1px solid grey",
    marginTop: "5px",
    backgroundColor: "lightblue",
    borderRadius: "5px", 
    padding: "10px",
    position: "relative",
  },
  regenerateBtn: {
    padding: "8px",
    position: "absolute",
    right: "60px",
    top: "7px",
    backgroundColor: "#fafafa",
    cursor: "pointer",
  },
  tooltipContent: {
    padding: "5px",
    maxWidth: "500px",
    fontSize: "16px",
  },
  boldTtile: {
    fontWeight: 800,
    display: "inline-block",
    margin: "5px 5px 5px 0",

  },
  errorTitle: {
    fontWeight: 800,
    display: "inline-block",
    marginRight: "5px",
    color: "#B00020",
  },
  presetEdit: {
    width: "100%",
    marginBottom: "10px !important",
  },
  editFields: {
    marginTop: "10px",
  },
  attrContainer: {
    border: "1px solid grey",
    borderRadius: "4px",
    backgroundColor: "#fff",
    padding: "6px",
    margin: "0 0 5px 5px",
  },
  attrHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  marketNames: {
    display: "grid",
    gridTemplateColumns: "2fr 2fr 4fr",
    padding: "5px",
    border: "1px solid black",
    alignItems: "center",
    backgroundColor: "#fafafa"
  },
  marketNamesHeader: {
    display: "grid",
    gridTemplateColumns: "2fr 2fr 4fr",
    width: "100%",
  },
  hidenAttrContainer: {
    padding: "5px",
    marginBottom: "10px",
    backgroundColor: "#fafafa",
  },
  attributeContent: {
    padding: "10px",
    display: "flex",
    justifyContent: "space-between",
    backgroundColor: "LightSlateGrey",
    borderRadius: "5px",
    borderBottom: "1px solid white",
  },
  hiddenAttrHeader: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "10px",
  },
  uploader: {
    padding: "10px",
    marginRight: "30px",
    backgroundColor: "#fafafa",
    cursor: "pointer",
    borderRadius: "5px"
  },
  popup: {
    display: "flex",
    justifyContent: "space-around",
    padding: "15px",
    borderRadius: "5px",
    backgroundColor: "#fafafa",
    minWidth: "200px",
  },
  notificationTooltip: {
    width: "20px",
    height: "20px",
    backgroundColor: "#00bdae",
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  }
});