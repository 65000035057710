import { Circle, Container} from "./HeaderCard.styles";
import { Typography } from "@mui/material";

export const HeaderCard = ({ title, subtitle}: { title: string, subtitle?: number}) => {
  return (
    <Container>
      <Circle />
      <div>
        <Typography color="#545454" sx={{mb: "2px"}}>{title}</Typography>
        <Typography color="#333" fontSize="39px" fontWeight={700}>{subtitle || "-"}</Typography>
      </div>
    </Container>
  );
};