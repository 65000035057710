import {GetUser_lookup_accounts_resolve_overview_inventory_items} from "pages/UserProfile/query/__generated__/GetUser";
import {useMemo} from "react";

export enum TableType {
  weapon = "weapon",
  outfit = "outfit",
  gears = "gears",
}

export const typeMapper = {
  [TableType.weapon]: ["MAIN_WEAPON_TYPE", "SECONDARY_WEAPON_TYPE", "main_weapon", "secondary_weapon"],
  [TableType.outfit]: ["PARACHUTE_TYPE", "OUTFIT_TYPE", "HAT_TYPE", "outfit", "parachute", "grenade"],
};

type GetInventoryColumnsResult = {
    gears: GetUser_lookup_accounts_resolve_overview_inventory_items[];
    outfit: GetUser_lookup_accounts_resolve_overview_inventory_items[];
    weapon: GetUser_lookup_accounts_resolve_overview_inventory_items[];
}

export const getInventoryColumns = (items: GetUser_lookup_accounts_resolve_overview_inventory_items[]): GetInventoryColumnsResult => {
  return items.reduce<GetInventoryColumnsResult>((acc, el) => {
    if(typeMapper.weapon.includes(el.overview.type)) {
      acc.weapon.push(el);
      return acc;
    }
    if(typeMapper.outfit.includes(el.overview.type)) {
      acc.outfit.push(el);
      return acc;
    }
    acc.gears.push(el);
    return acc;
  }, {
    gears: [],
    weapon: [],
    outfit: [],
  });
};

export const useSearchByNameAndId = (items: GetUser_lookup_accounts_resolve_overview_inventory_items[], search: string) => {
  const result = useMemo(() => {
    if(search === "") {
      return items;
    }
    return items.filter(el => {
      const nameMatch = el.overview.name.toLowerCase().includes(search.toLowerCase());
      const idMatch = el.id.toLowerCase().includes(search.toLowerCase());
      return nameMatch || idMatch;
    } );
  }, [search, items]);
  return result;

};
