import { gql } from "@apollo/client";

export const CREATE_COLLECTION = gql`
  mutation gameImageCollectionCreate(
    $name: String!
    $description: String!
  ) {
    gameImageCollectionCreate(
      input: {
        name: $name,
        description: $description
      }
    ) {
      collectionID,
      updatedAt,
      createdAt,
      name,
      description,
    }
  }
`;

export const DELETE_COLLECTION = gql`
    mutation deleteCollection(
        $id: UUID!
    ) {
        gameImageCollectionDelete(
        id: $id
        ) {
        ok
        }
    }
`;

export const DELETE_IMAGE = gql`
    mutation gameImageDelete(
        $id: UUID!
    ) {
        gameImageDelete(
        id: $id
        ) {
        ok
        }
    }
`;

export const UPDATE_COLLECTION = gql`
    mutation deleteCollection($collectionID: UUID!, $name: String!, $description: String!) {
    gameImageCollectionUpdate(
        input: {collectionID: $collectionID, name: $name, description: $description}
    ) {
    collectionID,
    createdAt,
    updatedAt,
    name,
    description,
  }
}
`;

export const CREATE_IMAGE = gql`
    mutation createImage($collectionID: UUID!, $startDate: Time!, $isBuiltin: Boolean!, $image: String!, $redirectURL: String!) {
    gameImageCreate(
        input: {
        collectionID: $collectionID,
        startDate: $startDate,
        isBuiltin: $isBuiltin,
        image: $image,
        redirectURL: $redirectURL,
        
        }
    ) {
    collectionID,
    createdAt,
    updatedAt,
    imageID,
		image,
		startDate,
    isBuiltin,
    redirectURL,
  }
}
`;

export const UPDATE_IMAGE = gql`
    mutation updateImage($imageID: UUID!, $startDate: Time!, $isBuiltin: Boolean!, $image: String!, $redirectURL: String!) {
    gameImageUpdate(
        input: {
        imageID: $imageID,
        startDate: $startDate,
        isBuiltin: $isBuiltin,
        image: $image,
        redirectURL: $redirectURL,
        
        }
    ) {
    collectionID,
    createdAt,
    updatedAt,
    imageID,
		image,
		startDate,
    isBuiltin,
    redirectURL,
  }
}
`;