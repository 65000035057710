import { useNavigate } from "react-router-dom";
import { FormWeapon } from "../FormWeapon";
import {
  // formatDataFiles,
  formatDataWeaponConfigCreate,
} from "../FormWeapon/contstants";
import { IFormWeapon } from "../FormWeapon/types";
import { useMarketNameUpdate } from "../hooks";
import { useWeaponConfigCreate } from "./hooks";

export const NewWeapon: React.FC = () => {
  const { weaponConfigCreate, error } = useWeaponConfigCreate();
  // const { uploadFiles, error: errorUpload } = useUploadFiles();
  const { updateMarketName, error: errorUpdateMarketName } =
    useMarketNameUpdate();
  const navigate = useNavigate();

  let errorMessage: string | undefined;

  // if (errorUpload) {
  //   errorMessage = errorUpload.message;
  // }

  if (error) {
    errorMessage = error?.message;
  }

  if (errorUpdateMarketName) {
    errorMessage = errorUpdateMarketName.message;
  }

  const onSubmit = async (values: IFormWeapon) => {
    try {
      // const dataFiles = formatDataFiles(values);
      await updateMarketName({
        variables: {
          input: {
            attribute: "WeaponSkin",
            marketName: values.marketNameSkin,
            value: values.weaponSkin,
          },
        },
      });
      // await uploadFiles({ variables: { input: dataFiles } });

      await weaponConfigCreate({
        variables: formatDataWeaponConfigCreate(values),
      });
      alert("Сonfiguration of weapon has been created");
      navigate("..");
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <FormWeapon type="new" onSubmit={onSubmit} errorMessage={errorMessage} />
  );
};
