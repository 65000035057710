import { v4 as uuidv4 } from "uuid";
import {
  // MaterialParamsInput,
  PerkSlotInput,
  // TextureInput,
  // WeaponConfigUploadResourcesInput,
} from "graphql-global-types/types";
import {
  GetPerksAndConditions_lookup_attributes_defaultWeaponConditions,
  GetPerksAndConditions_lookup_attributes_defaultWeaponPerksSlots,
} from "../hooks/useGetPerksAndConditions/__generated__/GetPerksAndConditions";

import {
  IFormWeapon,
  IPerk,
  IPerkSlot,
  IWeaponMaterial,
  RarityEnum,
} from "./types";

export const generateMaterialData = (): IWeaponMaterial => ({
  id: uuidv4(),
  name: "",
  withNormalMap: false,
  withEmissionMap: false,
  withAmbientOcclusion: false,
  textures: [],
  useSpecificParams: false,
});

export const initForm: IFormWeapon = {
  id: "",
  weaponType: "",
  weaponSkin: "",
  nameCounterID: "",
  season: "",
  minMintLevel: 0,
  dropType: "",
  rarity: RarityEnum.COMMON,
  useSpecificModel: false,
  materials: [generateMaterialData()],
  perksSlot: [],
  conditions: [],
  marketNameSkin: "",
};

export const formatPerksToFileds = (
  defaultWeaponPerksSlots: GetPerksAndConditions_lookup_attributes_defaultWeaponPerksSlots[]
) =>
  defaultWeaponPerksSlots.map(({ __typename, ...perk }) => {
    const customPerk = { ...perk } as unknown as IPerkSlot;
    const newPerks: IPerk[] = perk.perks.map((item) => {
      const newItem: IPerk = { ...item, checked: true };

      return newItem;
    });

    customPerk.perks = newPerks;

    return customPerk;
  });

export const formatConditionsToFileds = (
  defaultPerks: GetPerksAndConditions_lookup_attributes_defaultWeaponConditions[]
) =>
  defaultPerks.map(({ __typename, ...perk }) => {
    const customPerk = { ...perk } as unknown as IPerk;
    customPerk.checked = true;

    return customPerk;
  });

export const formatDataWeaponConfigCreate = (values: IFormWeapon) => ({
  id: values.id,
  nameCounterID: values.nameCounterID,
  weaponType: values.weaponType,
  weaponSkin: values.weaponSkin,
  minMintLevel: +values.minMintLevel,
  season: values.season,
  dropType: values.dropType,
  rarity: values.rarity,
  render: {
    useSpecificModel: values.useSpecificModel,
    materials: values.materials.map(
      ({
        name,
        withAmbientOcclusion,
        withEmissionMap,
        withNormalMap,
        useSpecificParams,
      }) => ({
        name,
        withAmbientOcclusion,
        withEmissionMap,
        withNormalMap,
        useSpecificParams,
      })
    ),
  },
  randomization: {
    perksSlots: values.perksSlot.map((slot) => {
      const newSlot = { ...slot } as PerkSlotInput;
      newSlot.perks = slot.perks
        .filter((item) => item.checked)
        .map(({ weight, name }) => ({ weight, name }));

      return newSlot;
    }),
    conditions: values.conditions
      .filter((item) => item.checked)
      .map(({ weight, name }) => ({ weight, name })),
  },
});

// export const formatDataFiles = (
//   values: IFormWeapon
// ): WeaponConfigUploadResourcesInput => {
//   const { model, weaponSkin, weaponType, materials, icon } = values;
//   const textures: TextureInput[] = [];
//   const jsons: MaterialParamsInput[] = [];

//   materials.forEach((material) => {
//     const json = material.json;

//     material.textures.forEach((texture) => {
//       textures.push({
//         file: texture.base64,
//         textureType: texture.textureType,
//         materialName: material.name,
//         weaponSkin,
//         weaponType,
//       });
//     });

//     if (json) {
//       jsons.push({
//         file: json.base64,
//         materialName: material.name,
//         weaponSkin,
//         weaponType,
//       });
//     }
//   });

//   values.useSpecificModel;

//   const defaultModel = {
//     defaultModels: model
//       ? [{ file: model.base64, weaponType }]
//       : undefined,
//     textures,
//     icons: icon ? [{ file: icon.base64, weaponSkin, weaponType }] : undefined,
//     materialParams: jsons.length ? jsons : undefined,
//   };

//   const customModel = {
//     models: model
//       ? [{ file: model.base64, weaponSkin, weaponType }]
//       : undefined,
//     textures,
//     icons: icon ? [{ file: icon.base64, weaponSkin, weaponType }] : undefined,
//     materialParams: jsons.length ? jsons : undefined,
//   };

//   return values?.useSpecificModel ?  customModel : defaultModel;
// };
