import { gql } from "@apollo/client";

export const updateUserMutation = gql`
    mutation idpUpdateUser(
        $userId: UUID!,
        $username: String!,
        $suffix: String!,
        $email: String!,
        $verifiedAt: Time!
    ) {
        idpUpdateUser(
            input: {
                userID: $userId,
                username: $username,
                suffix: $suffix,
                email: $email,
                verifiedAt: $verifiedAt,
            }
        ) {
            ok
        }
    }
`;
