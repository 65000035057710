import { Button, Popover } from "@mui/material";
import { useStyles } from "pages/CgsServise/Cgs.style";

interface IpopoverProps {
    anchorEl: Element | null;
    handleClose: () => void;
    onClick: () => void;
    title: string;
}

export const CustomPopover = ({ anchorEl, title, handleClose, onClick}: IpopoverProps) => {

  const classes = useStyles();

  const handleClick = () => {
    onClick();
    handleClose();
  };

  return (
    <Popover
      open={!!anchorEl}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
    >
      <div className={classes.popup}>
        <Button
          variant="contained" 
          color="success" 
          onClick={handleClick}
        >
          {title}
        </Button>
        <Button
          variant="contained"
          color="error"
          onClick={handleClose}
        >
        Cancel
        </Button>
      </div>
    </Popover>);
};