import { useState } from "react";
import { useQuery, useMutation, useLazyQuery } from "@apollo/client";
import { GET_IMG_COLLECTIONS, GET_IMAGES } from "query/imageCollections";
import { CREATE_COLLECTION, DELETE_COLLECTION, UPDATE_COLLECTION, CREATE_IMAGE, DELETE_IMAGE, UPDATE_IMAGE } from "mutations/images";
import TextField from "@mui/material/TextField";
import DeleteIcon from "@mui/icons-material/Delete";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import Button from "@mui/material/Button";
import Switch from "@mui/material/Switch";
import Modal from "@mui/material/Modal";
import { useStyles } from "pages/MiniroyaleImages/MiniroyaleImages.style";
import moment from "moment";

export const MiniroyaleImagesPage = () => {

  const [imageUrl, setImageUrl] = useState("");
  const [redirectUrl, setredirectUrl] = useState("");
  const [collectionName, setCollectionName] = useState("");
  const [collectionDesc, setCollectionDesc] = useState("");
  const [selectedCollection, setCollection] = useState(null as any);
  const [isBuiltIn, setBiultIn] = useState(false);
  const [open, setOpen] = useState(false);
  const [date, setDate] = useState(null);
  const [selectedImg, setImg] = useState("");

  const classes = useStyles();

  const [getImages, { data: imagesData, refetch: refetchImages }] = useLazyQuery(GET_IMAGES, {
    variables: {
      imageID: null,
      collectionID: null,
    },
  });

  const { data, refetch } = useQuery(GET_IMG_COLLECTIONS, {
    variables: {
      id: null,
    },
  });

  const [createCollection, { data: d1, loading: l1, error: e1 }] = useMutation(
    CREATE_COLLECTION,
    {
      variables: {
        name: collectionName,
        description: collectionDesc,
      },
    }
  );

  const [deleteCollection, { data: d2, loading: l2, error: e2 }] = useMutation(DELETE_COLLECTION);

  const [deleteImage, { data: d5, loading: l5, error: e5 }] = useMutation(DELETE_IMAGE);

  const [updateCollection, { data: d3, loading: l3, error: e3 }] = useMutation(
    UPDATE_COLLECTION,
    {
      variables: {
        collectionID: "1cc05538-ba8e-425d-91ea-0393ce9e43cf",
        name: "edited туц барабам",
        description: "editedd"
      },
    }
  );

  const [createImage, { data: d4 }] = useMutation(CREATE_IMAGE);

  const [updateImage, { data: d6 }] = useMutation(UPDATE_IMAGE);

  const handleOpen = (img: any) => {
    setImageUrl(img?.image);
    setredirectUrl(img?.redirectURL);
    setBiultIn(img?.isBuiltin);
    setImg(img?.imageID);
    setDate(img?.startDate);
    setOpen(true);
  };

  return (
    <div style={{ width: "100%" }}>
      <div style={{ width: "100%"}}>
        <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "10px", width: "100%"}}>
          <div style={{ marginBottom: "10px", padding: "20px", display: "flex", alignItems: "center"}}>
            <TextField
              id="outlined-basic"
              label="collection name"
              variant="outlined"
              className={classes.input}
              onChange={(e) => setCollectionName(e.target.value)}
            />
            <TextField
              id="outlined-basic"
              label="collection description"
              variant="outlined"
              className={classes.input}
              onChange={(e) => setCollectionDesc(e.target.value)}
            />
            <Button variant="contained" onClick={() => createCollection().then(() => refetch())}>create Collection</Button>
          </div>
          {selectedCollection && <div style={{ padding: "20px" }}>
            <TextField
              id="outlined-basic"
              label="image URL"
              variant="outlined"
              className={classes.input}
              onChange={(e) => setImageUrl(e.target.value)}
            />
            <TextField
              id="outlined-basic"
              label="redirect URL"
              variant="outlined"
              className={classes.input}
              onChange={(e) => setredirectUrl(e.target.value)}
            />
            <LocalizationProvider dateAdapter={AdapterDateFns} style={{marginTop: "15px"}}>
              <DateTimePicker
                label="Start Date"
                value={date}
                onChange={(newDate: any) => {
                  setDate(newDate);
                }}
                renderInput={(params: any) => <TextField {...params} />}
              />
            </LocalizationProvider>
            <div>isBuiltin: <Switch onChange={() => setBiultIn(!isBuiltIn)} /></div>
            <Button variant="contained" onClick={() => createImage({
              variables: {
                collectionID: selectedCollection.collectionID,
                startDate: date || new Date(),
                isBuiltin: isBuiltIn,
                image: imageUrl,
                redirectURL: redirectUrl,
              },
            }).then(() => refetchImages())}>create Image
            </Button>
          </div>}
        </div>
      </div>
      <div style={{ padding: "20px", display: "flex"}}>
        <div className={classes.continer}>
          <div style={{ marginBottom: "10px", fontSize: "20px"}}>Collections:</div>
          <div className={classes.collectionTitle}>Name:<span>Description:</span></div>
          {data?.lookup?.gameImages?.collections?.map((item: any, i: number) => 
            <div
              onClick={() => {
                setCollection(item);
                getImages({ variables: { imageID: null, collectionID: item.collectionID} });}}
              className={classes.collectionItem}
              key={i}
            >
              {item?.name}
              <span>{item?.description}</span>
              <div>
                {/* <button onClick={() => undefined}>
                edit
              </button> */}
                <DeleteIcon
                  onClick={() => {
                    deleteCollection({ variables: { id: item.collectionID } }).then(() => {
                      setCollection(null);
                      refetch();});
                  }} />
              </div>
            </div>
          )}
        </div>
        <div className={classes.continer}>
          <div style={{ marginBottom: "10px", fontSize: "20px"}}>Collection: {selectedCollection?.name}</div>
          <div style={{position: "relative"}}>
            {imagesData?.lookup?.gameImages?.images.map((img: any, i: number) => {
              console.log(img, "00");
              const startDate = moment(img?.startDate).format("MMMM Do YYYY, h:mm:ss a");
              const createdAt = moment(img?.createdAt).format("MMMM Do YYYY, h:mm:ss a");
              const updatedAt = moment(img?.updatedAt).format("MMMM Do YYYY, h:mm:ss a");
              return (
                <div className={classes.imageItem} key={i}>
                  <div className={classes.imageTitle}>image: {img?.image}</div>
                  <div className={classes.imageTitle}>redirectUrl: {img?.redirectURL}</div>
                  <div className={classes.imageTitle}>isBuiltin: {`${img?.isBuiltin}`}</div>
                  <div className={classes.imageTitle}>imageID: {img?.imageID}</div>
                  <div className={classes.imageTitle}>startDate: {startDate}</div>
                  <div className={classes.imageTitle}>createdAt: {createdAt}</div>
                  <div className={classes.imageTitle}>updatedAt: {updatedAt || "-"}</div>
                  <EditIcon className={classes.editIcon} onClick={() => handleOpen(img)} />
                  <DeleteIcon 
                    className={classes.delIcon}
                    onClick={() => deleteImage({ variables: { id: img.imageID } }).then(() => refetchImages())} 
                  />
                </div>
              );})}
          </div>
          <Modal
            open={open}
            onClose={() => setOpen(true)}
            aria-labelledby="child-modal-title"
            aria-describedby="child-modal-description"
          >
            <div className={classes.modalContainer}>
              <CloseIcon className={classes.delIcon} onClick={() => setOpen(false)} />
              <TextField
                id="outlined-basic"
                label="image URL"
                variant="outlined"
                value={imageUrl}
                className={classes.modalInput}
                onChange={(e) => setImageUrl(e.target.value)}
              />
              <TextField
                id="outlined-basic"
                label="redirect URL"
                variant="outlined"
                value={redirectUrl}
                className={classes.modalInput}
                onChange={(e) => setredirectUrl(e.target.value)}
              />
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DateTimePicker
                  label="Start Date"
                  value={date}
                  onChange={(newDate: any) => {
                    setDate(newDate);
                  }}
                  renderInput={(params: any) => <TextField {...params} />}
                />
              </LocalizationProvider>
              <div>isBuiltin: <Switch checked={isBuiltIn} onChange={() => setBiultIn(!isBuiltIn)} /></div>
              <div style={{ justifyContent: "center", display: "flex"}}>
                <Button variant="contained" onClick={() => updateImage({
                  variables: {
                    imageID: selectedImg,
                    startDate: date,
                    isBuiltin: isBuiltIn,
                    image: imageUrl,
                    redirectURL: redirectUrl,
                  },
                }).then(() => {
                  refetchImages();
                  setOpen(false);
                })}>
                  Update
                </Button>
              </div>
            </div>
          </Modal>
        </div>
      </div>
    </div>);
};