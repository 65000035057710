import { gql } from "@apollo/client";

export const GET_MINTABLE_ITEMS = gql`
  query search($query: String!) {
    lookup {
      itemConfigs {
        resolve(
          input: {
            query: $query
            itemType: CONFIGS
            pagination: { limit: 10, offset: 0 }
          }
        ) {
          __typename
          ... on MintableItemConfig {
            configID
          }
        }
      }
    }
  }
`;
export const GET_GAMEDATA_ITEMS = gql`
  query search($query: String!) {
    lookup {
      itemConfigs {
        resolve(
          input: {
            query: $query
            itemType: GAMEDATA
            pagination: { limit: 10, offset: 0 }
          }
        ) {
          __typename
          ... on GamedataItemConfig {
            itemID
            name
          }
        }
      }
    }
  }
`;
