type TLinks = { path: string; name: string }[];

export const links: TLinks = [
  { path: "/dashboard", name: "Dashboard" },
  { path: "/history", name: "History" },
  { path: "/rewardHistory", name: "Reward History" },
  { path: "/rewardUser", name: "Reward User" },
  { path: "/weapons", name: "Weapons" },
  { path: "/miniroyaleImages", name: "MiniroyaleImages" },
  { path: "/clanEvents", name: "ClanEvents" },
  { path: "/cgsService", name: "CgsAdmin" },
  { path: "/settings", name: "Settings" },
];
