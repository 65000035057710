import { useEffect, useState } from "react";
import { useQuery } from "@apollo/client";
import { GET_FILESTREE, GET_COLLECTIONS } from "query/cgsServise";
import Button from "@mui/material/Button";
import { v4 as uuidv4 } from "uuid";
import { CustomTreeItem } from "./TreeComponent";
import { ITreeData, ILookupData, ISelectedDataFromPreset, IErrorlsList } from "./Cgs.types";
import { useStyles } from "pages/CgsServise/Cgs.style";
import { CgsModal } from "./CgsModal";
import { Collections } from "./Collections";
import { TaskProgressStatus } from "./TaskStatus/TaskStatus";
import { useNavigate } from "react-router-dom";


export const CgsPage = () => {
  const nav = useNavigate();

  const classes = useStyles();
  const [filesData, setData] = useState<ITreeData[]>([]);
  const [isOpen, setOpen] = useState(false);
  const [selected, setItems] = useState<ITreeData[]>([]);
  const [errorsList, setErrors] = useState<IErrorlsList[]>([]);

  const { data } = useQuery<ILookupData>(GET_FILESTREE);

  useEffect(() => {
    setData(setItemstoData(data?.lookup?.cgsAdmin?.files || []));
  }, [data]);

  useEffect(() => {

    const updateFileTree = (items: ITreeData[]): ITreeData[] => {
      return items?.map((treeItem: ITreeData) => {

        const mappedChildren = treeItem?.children?.length ? updateFileTree(treeItem?.children) : undefined;
        const included = !!selected.find((selectedFile) => selectedFile.id === treeItem.id || selectedFile.attributeValue === treeItem.attributeValue);
        
        return {...treeItem, selected: included ? true : false, children: mappedChildren };
      });
    };

    setData(updateFileTree(filesData));

  }, [selected]);

  const setSelectedfromPreset = (selectedFromPreset: ISelectedDataFromPreset[], allItems: ITreeData[]): ITreeData[] => {
    return allItems?.map((treeItem: ITreeData) => {
      const mappedChildren = treeItem?.children?.length ? setSelectedfromPreset(selectedFromPreset, treeItem?.children ) : undefined;
      const included = !!selectedFromPreset?.find(presetItem => presetItem.attributeValue === treeItem.attributeValue);
   
      return {...treeItem, selected: included ? true : false, children: mappedChildren };
    });
  };

  const setDataFromPreset = (data: ISelectedDataFromPreset[]) => {
    setData(setSelectedfromPreset(data, filesData));
  };

  const setItemstoData = (items: ITreeData[], prevPath?: string): ITreeData[] => {
    return items?.map((item: ITreeData) => {

      const path = `${prevPath || ""}/${item.name}`;
      const mappedChildren = item?.children?.length ? setItemstoData(item?.children, path) : undefined;

      if(item?.errors && item?.errors[0].length) {
        setErrors((prevState: IErrorlsList[]) => [...prevState, {errors: item.errors, path}]);
      }
      return {...item, selected: false, id: uuidv4(), children: mappedChildren, path };
    });
  };

  const setSelected = (nodeId: string, attrValueName: string) => {
    const isSelected = selected?.find((item: ITreeData) => (item.id === nodeId) || (item.attributeValue === attrValueName));

    if(isSelected && attrValueName) {
      const newArr = selected.filter((item: ITreeData) =>  item.id ? item.id !== nodeId : item.attributeValue !== attrValueName);
      setItems(newArr);
    } else {
      const currentItem: ITreeData[] = [];
      const addFileToSelected = (item: ITreeData): void => {
        if(item.children) {
          item.children.forEach((file: ITreeData) => currentItem.push(file));
        } else {
          currentItem.push(item);
        }
      };

      const setElement = (items?: ITreeData[], nodeId?: string) => {
        items?.forEach((item: ITreeData) => item.id === nodeId ? addFileToSelected(item) : setElement(item?.children, nodeId));
      };

      setElement(filesData, nodeId);

      const isFolderFull = currentItem.length > 1 && currentItem.every((currItem) => selected.find(item => item.attributeValue === currItem.attributeValue));

      if(isFolderFull){
        
        const filteredItems = selected.filter((selectedItem) => !currentItem.find(item => item.attributeValue === selectedItem.attributeValue));

        setItems(filteredItems);
      } else {
        const uniqePresets = currentItem.filter((currItem) => !selected.find(item => item.attributeValue === currItem.attributeValue));
  
        setItems([...selected, ...uniqePresets]);
      }

    }
  };

  const getSelectedCount = (isLastFoled: boolean, item: ITreeData) => {
    if(isLastFoled) {
      return item.children?.filter(item => item.selected).length;
    } else {
      return 0;
    }

  };

  const TreeRender = (items: ITreeData[]) => {
    return items?.map((item: ITreeData, i: number) => {
      const isActive = item?.attributeName && !!item?.attributeValue && "active";
      const haveErrors = item?.errors && item?.errors[0].length;

     
      return (
        <CustomTreeItem
          key={i} nodeId={item.id} 
          label={item?.name}
          ContentProps={
            {
              errors: item?.errors,
              setSelected: setSelected,
              haveErrors: haveErrors,
              count: getSelectedCount(!item.children?.[0].children, item),
              isLastFolder: !item.children?.[0].children,
              isFolderSelected: getSelectedCount(!item.children?.[0].children, item) === item.children?.length,
              isSelected: item.selected,
              attrValueName: item?.attributeValue,
              active: isActive,
            }
          }
        >
          {item?.children?.length && TreeRender(item?.children)}
        </CustomTreeItem>
      );});
  };

  const { data: collections, refetch: refetchCollections } = useQuery<ILookupData>(GET_COLLECTIONS, {
    fetchPolicy: "no-cache",
  });

  return (
    <div className={classes.cgsWrapper}>
      <div>
        <Button
          style={{marginBottom: "10px"}}
          variant="contained"
          color="secondary"
          onClick={() => setOpen(true)}>
              create collection
        </Button>
        <Button
          sx={{ ml: "20px", mb: "10px"}}
          variant="contained"
          onClick={() => nav("/cgsService/logs")}>
              logs
        </Button>
        <div>Collections:</div>
        <div>
          <Collections
            collections={collections}
            TreeRender={TreeRender}
            filesData={filesData}
            selectedFiles={selected}
            setItems={setItems}
            refetchCollections={refetchCollections}
            errorsList={errorsList}
            setDataFromPreset={setDataFromPreset}
            setData={setData}
          />
        </div>
        <TaskProgressStatus />
      </div>
      <CgsModal
        isOpen={isOpen}
        setOpen={setOpen}
        collection={"create"} 
        refetchCollections={refetchCollections}
      />
    </div>
  );
};