import React from "react";
import {DialogTitleWithCross} from "components/DialogTitleWithCross";
import {Dialog, DialogContent, Grid, Typography} from "@mui/material";
import {AccountForm} from "./components/AccountForm";
import {ExperienceForm} from "./components/ExperienceForm";

type PlayerEditModalProps = {
    onClose: () => void;
}

export const PlayerEditModal = ({onClose}: PlayerEditModalProps) => {
  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open
      onClose={onClose}
    >
      <DialogTitleWithCross onClose={onClose}>Edit player</DialogTitleWithCross>
      <DialogContent>
        <Grid container direction="column">
          <AccountForm onSuccess={onClose} />
          <ExperienceForm onSuccess={onClose}/>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};
