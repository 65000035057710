import React, {useState} from "react";
import {Grid, Paper, Typography} from "@mui/material";
import TextField from "@mui/material/TextField";
import {ItemsTable} from "../ItemsTable";
import {
  GetUser_lookup_accounts_resolve_overview_inventory_items
} from "pages/UserProfile/query/__generated__/GetUser";

type ItemsCardProps = {
    label: string;
    items: GetUser_lookup_accounts_resolve_overview_inventory_items[];
    onItemSelect: (item: string) => void;
}

export const ItemsCard = ({label, items, onItemSelect}: ItemsCardProps) => {

  const [search, setSearch] = useState("");

  return (
    <Grid key={label} mt={3} item xs={4}>
      <Paper sx={{p: 1}}>
        <Grid container alignItems="center">
          <Grid item xs={4}>
            <Typography align="center" color="rgba(119, 119, 119, 1)">
              {label} <Typography color="black" fontWeight={700} component="span"> ( {items.length} )</Typography>
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <TextField
              size="small"
              label="Search"
              variant="outlined"
              fullWidth
              onChange={(e) => setSearch(e.target.value)}/>
          </Grid>
          <Grid mt={2} item xs={12}>
            <ItemsTable onItemSelect={onItemSelect} search={search} items={items}/>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
};
