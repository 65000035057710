import React, { useEffect } from "react";
import FolderIcon from "@mui/icons-material/Folder";
import FilePresentIcon from "@mui/icons-material/FilePresent";
import Checkbox from "@mui/material/Checkbox";
import TreeItem, {
  useTreeItem,
  TreeItemContentProps,
} from "@mui/lab/TreeItem";
import clsx from "clsx";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import { useStyles } from "pages/CgsServise/Cgs.style";


interface ICustomContentProps extends TreeItemContentProps {
  active: string;
  isSelected: boolean;
  setSelected: (val: string, attrValueName: string) => void;
  haveErrors: boolean;
  errors: string[] | null;
  attrValueName: string;
  isLastFolder: boolean;
  count: number;
  isFolderSelected: boolean;
}

const CustomContent = React.forwardRef(function CustomContent(
  props: ICustomContentProps,
  ref,
) {
  const {
    classes,
    className,
    label,
    nodeId,
    icon: iconProp,
    expansionIcon,
    displayIcon,
    active,
    haveErrors,
    isLastFolder,
    setSelected,
    errors,
    isSelected,
    attrValueName,
    count,
    isFolderSelected,
  } = props;
  
  const {
    disabled,
    expanded,
    selected,
    focused,
    handleExpansion,
    handleSelection,
    preventSelection,
  } = useTreeItem(nodeId);
  
  const icon = iconProp || expansionIcon || displayIcon;

  const customClass = useStyles();
  
  const handleMouseDown = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    preventSelection(event);
  };
  
  const handleExpansionClick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => {
    handleExpansion(event);
  };
  let x;
  useEffect(() => {
    x = !expanded;
  }, [expanded]);

  const handleSelectionClick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => {
    handleSelection(event);
  };
  const isFile = (label as string)?.includes(".");

  return (
    <div
      className={clsx(className, classes.root, {
        [classes.expanded]: x,
        [classes.selected]: selected,
        [classes.focused]: focused,
        [classes.disabled]: disabled,
      })}
      onMouseDown={handleMouseDown}
      ref={ref as React.Ref<HTMLDivElement>}
    >
      <div onClick={handleExpansionClick} className={classes.iconContainer}>
        {icon}
      </div>
      <Typography
        onClick={handleSelectionClick}
        component="div"
        className={classes.label}
      >
        <div style={{ display: "flex", alignItems: "center"}}>
          <Tooltip
            placement="top-start"
            sx={{
              color: haveErrors ? "#B00020" : "inherit",
            }}
            title={
              haveErrors ? <div style={{ maxHeight: "200px", overflow: "scroll"}}>
                {errors?.map((error: string, i: number) =>  <div key={i} className={customClass.tooltipContent}>{i}.{error}</div>)}
              </div> : ""
            }
          >
            {isFile ? 
              <FilePresentIcon /> :
              <FolderIcon style={{ marginRight: "8px"}} />}
          </Tooltip>
          {label}
          {active !== "active" && isLastFolder && !haveErrors && <Checkbox sx={{padding: "0"}} checked={isFolderSelected} onChange={() => setSelected(nodeId, attrValueName)} />}
          {active !== "active" && isLastFolder && !!count && <div className={customClass.notificationTooltip}>{count}</div>}
          {active === "active" && <Checkbox sx={{padding: "0"}} checked={isSelected} onChange={() => setSelected(nodeId, attrValueName)} />}
        </div>
      </Typography>
    </div>
  );
});
  
export const CustomTreeItem = (props: any) => {
  return <TreeItem ContentComponent={CustomContent} {...props}/>;
};