import React from "react";
import { Box } from "components/Box";

export const RewardHistory = () => {
  return (
    <Box w={300} h={200} alignItems="center" justifyContent="center">
      Under Construction
    </Box>
  );
};
