import { Box, FormControl, FormControlLabel, Switch } from "@mui/material";
import { IPerk } from "pages/Weapons/FormWeapon/types";
import { FormikHandlers } from "formik";
import { TextFieldWrapper } from "../TextFieldWrapper";

const InputProps = { inputProps: { min: 0, max: 100, step: 1 } };

export const PerkItem: React.FC<{
  perk: IPerk;
  handleChange: FormikHandlers["handleChange"];
  name: string;
}> = ({ perk, handleChange, name }) => {
  const nameChecked = `${name}.checked`;
  const nameWeight = `${name}.weight`;

  return (
    <FormControl fullWidth margin="dense">
      <Box>
        <FormControlLabel
          control={
            <Switch
              checked={perk.checked}
              name={nameChecked}
              key={nameChecked}
              id={nameChecked}
              onChange={handleChange}
            />
          }
          label={perk.name}
        />

        <TextFieldWrapper
          name={nameWeight}
          label="Weight"
          InputProps={InputProps}
          size="small"
          type="number"
        />
      </Box>
    </FormControl>
  );
};
