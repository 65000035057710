import { Route, Routes } from "react-router-dom";
import { EditWeaponCofing } from "./EditWeaponConfig";
import { FormWeapon } from "./FormWeapon";
import { NewWeapon } from "./NewWeapon";
import { WeaponList } from "./WeaponList";

export const Weapons: React.FC = () => (
  <Routes>
    <Route path="/" element={<WeaponList />} />
    <Route path="/new" element={<NewWeapon />} />
    <Route path="/:id" element={<EditWeaponCofing />} />
  </Routes>
);
