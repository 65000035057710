import React, {ReactNode} from "react";
import {Dialog, Grid, Typography, Button} from "@mui/material";

type FeedbackModalProps = {
    onClose: () => void;
    label: ReactNode;
    buttonLabel: string;
}

export const FeedbackModal = ({onClose, buttonLabel, label}: FeedbackModalProps) => {
  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open
      onClose={onClose}
    >
      <Grid container justifyContent="space-between" alignItems="center" sx={{background: "black", padding: "8px 24px"}}  >
        <Grid item>
          <Typography color="white">
            {label}
          </Typography>
        </Grid>
        <Grid item>
          <Button variant='contained' color="secondary" onClick={onClose}>
            {buttonLabel}
          </Button>
        </Grid>
      </Grid>
    </Dialog>
  );
};
