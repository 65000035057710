import React from "react";
import {Grid, Paper, Typography} from "@mui/material";
import {useUserProfileContext} from "pages/UserProfile/context";

export const ClanInfo = () => {
  const {data} = useUserProfileContext();
  if(!data.overview.clan) {
    return null;
  }

  return (
    <Grid mt={3} item xs={12}>
      <Paper sx={{p: 2}}>
        <Grid container spacing={3}>
          <Grid item >
            <Typography fontSize={16}>Clan</Typography>
          </Grid>
          <Grid item>
            <Typography fontSize={16} color="#777777">Clan name:{" "}
              <Typography color="black" component="span" fontWeight={700} fontSize={16}>{data.overview.clan.name}</Typography>
            </Typography>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
};
