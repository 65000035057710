import { useQuery } from "@apollo/client";
import { queryGetPerksAndConditions } from "./getPerksAndConditions.graphql";
import { GetPerksAndConditions } from "./__generated__/GetPerksAndConditions";

export const useGetPerksAndConditions = () => {
  const { data, loading, error } = useQuery<GetPerksAndConditions>(
    queryGetPerksAndConditions
  );

  return {
    defaultWeaponConditions:
      data?.lookup.attributes?.defaultWeaponConditions ?? [],
    defaultWeaponPerksSlots:
      data?.lookup.attributes?.defaultWeaponPerksSlots ?? [],
    loading,
    error,
  };
};
