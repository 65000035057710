import { styled } from "@mui/system";

export const Container = styled("div")({
  display: "flex",
  backgroundColor: "#fff",
  alignItems: "center",
  "&:first-child": {
    marginRight: "69px",
  }
});

export const Circle = styled("div")({
  height: "60px",
  width: "60px",
  backgroundColor: "#D9D9D9",
  borderRadius: "50%",
  "&:first-child": {
    marginRight: "23px",
  }
});