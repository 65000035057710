import { gql } from "@apollo/client";

export const getMarketName = gql`
  query GetMarketName($input: ResolveMarketNameInput!) {
    lookup {
      marketName {
        get(input: $input) {
          attribute
          value
          name
        }
      }
    }
  }
`;
