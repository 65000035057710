import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  SxProps,
  Theme
} from "@mui/material";
  
  interface ISelectWrapperProps {
    opts: string[];
    name: string;
    label: string;
    value?: string;
    className?: string;
    setFn: (val: string)=> void
    style?: {[key: string]: string}
    sx?: SxProps<Theme>;
  }
  
export const CustomSelect: React.FC<ISelectWrapperProps> = ({
  opts,
  name,
  label,
  value,
  className,
  setFn,
  style,
  sx,
}) => {

  return (
    value !== undefined ? <FormControl fullWidth style={style}>
      <InputLabel id={name}>{label}</InputLabel>
      <Select
        labelId={name}
        name={name}
        className={className}
        id={name}
        label={label}
        value={value}
        defaultValue={value}
        onChange={(e) => setFn(e.target.value)}
        sx={sx}
      >
        {opts.map((item) => (
          <MenuItem value={item} key={item}>
            {item}
          </MenuItem>
        ))}
      </Select>
    </FormControl> : null
  );
};
  