import { Typography } from "@mui/material";
import { TextFieldWrapper } from "../TextFieldWrapper";
import { PerkWrapperStyled } from "./styled";
import { useFormikContext } from "formik";
import { IFormWeapon } from "pages/Weapons/FormWeapon/types";
import { PerkItem } from "./PerkItem";

const InputProps = { inputProps: { min: 0, max: 1, step: 0.1 } };

export const Perk: React.FC<{ index: number }> = ({ index }) => {
  const { values, handleChange } = useFormikContext<IFormWeapon>();

  return (
    <PerkWrapperStyled>
      <Typography variant="body2" mb={2}>
        perk #{index + 1}
      </Typography>

      <TextFieldWrapper
        name={`perksSlot.${index}.probability`}
        label="Probability"
        type="number"
        InputProps={InputProps}
      />

      {values.perksSlot[index].perks.map((perk, perkItemIndex) => {
        return (
          <PerkItem
            perk={perk}
            key={perk.name}
            name={`perksSlot.${index}.perks.${perkItemIndex}`}
            handleChange={handleChange}
          />
        );
      })}
    </PerkWrapperStyled>
  );
};
