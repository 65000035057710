import { gql } from "@apollo/client";

export const GET_ALL_USERS = gql`
  query search($username: String, $email: String, $discord: String) {
    lookup {
      accounts {
        search(
          input: {
            nextPage: { descending : false }
            filter: { username: $username, email: $email, discord: $discord }
          }
        ) {
          items {
            userId
            ident {
              username
              suffix
            }
            overview {
              gameAccount {
                xp
                level
              }
              discord {
                discordID
                username
                userhash
                locale
              },
              wallet {
                createdAt
                pubKey
              }
            }
            personalInfo {
              email
              timeline {
                createdAt
                verifiedAt
                retiredAt
              }
            }
          }
        }
      }
    }
  }
`;
