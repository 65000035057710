import {useEffect, useState} from "react";
import {Button, Chip, Divider, Grid, Paper} from "@mui/material";
import { useQuery } from "hooks/useQuery";
import Typography from "@mui/material/Typography";
import EditIcon from "@mui/icons-material/Edit";
import {BaseInfoItem} from "./components/BaseInfoItem";
import {TimelineItem} from "./components/TimelineItem";
import {PlayerEditModal} from "./components/PlayerEditModal";
import {useUserProfileContext} from "../../context";

export const BaseInfo = () => {

  const query = useQuery();
  const isEditModalOpenByDefault = !!query.get("edit");

  const [isPlayerEditModal, setPlayerEditModal] = useState(isEditModalOpenByDefault);
  const {data: userInfo} = useUserProfileContext();

  const baseInfoData = [
    {
      label: "Email",
      value: userInfo.personalInfo.email
    },
    {
      label: "UserID",
      value: userInfo.userId
    },
    {
      label: "Suffix",
      value: userInfo.ident.suffix
    },
    {
      label: "Wallet address",
      value: userInfo.overview.wallet?.pubKey
    },
    {
      label: "Discord name",
      value: userInfo.overview.discord?.username
    },
  ];
  const getTimelineData = () => {
    const res = [
      {
        label: "Created at",
        datetime: userInfo.personalInfo.timeline.createdAt
      },
      {
        label: "Verified status",
        datetime: userInfo.personalInfo.timeline.verifiedAt
      },
    ];

    if(userInfo.personalInfo.timeline.retiredAt) {
      res.push({
        label: "Retired at",
        datetime: userInfo.personalInfo.timeline.retiredAt
      });
    }

    return res;
  };

  const timelineData = getTimelineData();

  return (
    <Grid mt={3} item xs={12}>
      <Paper sx={{p: 2}}>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item>
            <Typography fontWeight={700} fontSize={18}>
              {userInfo.ident.username}
            </Typography>
          </Grid>
          <Grid item>
            <Button onClick={() => setPlayerEditModal(true)} color="secondary"  variant="contained" startIcon={<EditIcon />}>
                            Edit player
            </Button>
          </Grid>
        </Grid>
        <Divider sx={{mt: 2, mb: 2}}/>
        <Grid container spacing={2}>
          {baseInfoData.map(el => (
            <Grid key={el.label} item xs={6}>
              <BaseInfoItem label={el.label} value={el.value}/>
            </Grid>
          ))}

        </Grid>

        <Grid p={2} mt={2} alignItems="center" container sx={{background: "#F4F4F4"}}>
          {timelineData.map(el => (
            <Grid xs={2} pl={2} item key={el.label}>
              <TimelineItem label={el.label} datetime={el.datetime}/>
            </Grid>
          ))}
          {userInfo.personalInfo.timeline.retiredAt && (
            <Grid display="flex" item xs={6} justifyContent="flex-end">
              <Chip label="Banned"/>
            </Grid>
          )}
        </Grid>

      </Paper>
      {isPlayerEditModal && <PlayerEditModal onClose={() => {
        if(isEditModalOpenByDefault) window.location.href = window.location.href.replace("?edit=true", "");
        setPlayerEditModal(false);
      }}/>}
    </Grid>
  );
};
