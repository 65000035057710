import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles( props => ({
  wrapper: {
    background: "LightSteelBlue",
    padding: "10px",
    borderRadius: "6px",
    marginBottom: "15px",
    marginTop: "10px",
  },
  container: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "5px",
  },
  title: {
    fontSize: "18px",
    fontWeight: "bold"
  },
  label: {
    textAlign: "center",
    fontSize: "20px",
    fontWeight: "500",
    marginBottom: "5px",
  }
}));