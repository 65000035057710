import {
  Box,
  Button,
  Drawer,
  FormControlLabel,
  FormHelperText,
  Switch,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { Material } from "./components";
import { generateMaterialData } from "./contstants";
import { FieldArray, useFormikContext, useField } from "formik";
import { IFormWeapon } from "./types";

export const MaterialDrawer: React.FC = () => {
  const [show, setShow] = useState(false);
  const { values, handleChange } = useFormikContext<IFormWeapon>();
  const [, materialsMeta] = useField("materials");
  const [useSpecificModel] = useField("useSpecificModel");

  return (
    <>
      <Box display="flex" justifyContent="space-between" alignItems="start">
        <div>
          <Typography variant="h6" gutterBottom component="div">
            Weapon Config Render
          </Typography>
          {materialsMeta.error?.length ? (
            <FormHelperText error={Boolean(materialsMeta.error?.length)}>
              Fill in the required fields
            </FormHelperText>
          ) : null}
        </div>
        <Button variant="outlined" size="small" onClick={() => setShow(true)}>
          Customize material
        </Button>
      </Box>

      <Drawer
        anchor="right"
        open={show}
        onClose={() => setShow(false)}
        title="Weapon Config Render"
      >
        <Box width={600} padding={4}>
          <Typography variant="h6" gutterBottom component="div" mb={2}>
            Weapon Config Render
          </Typography>

          <FormControlLabel
            control={
              <Switch
                checked={useSpecificModel.value}
                name="useSpecificModel"
                onChange={handleChange}
              />
            }
            label="Specific model name"
            labelPlacement="start"
            sx={{ margin: 0 }}
          />
          <FieldArray
            name="materials"
            render={({ remove, push }) => {
              return (
                <div>
                  <Typography variant="body2" gutterBottom>
                    Materials:
                  </Typography>
                  {values.materials.map((item, index) => {
                    return (
                      <Material
                        material={item}
                        index={index}
                        key={item.id}
                        handleChange={handleChange}
                        removeMaterial={remove}
                      />
                    );
                  })}
                  <Button
                    variant="contained"
                    size="small"
                    onClick={() => push(generateMaterialData())}
                  >
                    Add Material
                  </Button>
                </div>
              );
            }}
          />
        </Box>
      </Drawer>
    </>
  );
};
