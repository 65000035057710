import { GetPerksAndConditions_lookup_attributes_defaultWeaponPerksSlots_perks } from "../hooks/useGetPerksAndConditions/__generated__/GetPerksAndConditions";
import {
  IImage,
  IJson,
  IModelFile,
  ITexture,
} from "./components/Uploaders/types";

export enum RarityEnum {
  COMMON = 1,
  UNCOMMON = 2,
  RARE = 3,
  EPIC = 4,
  LEGENDARY = 5,
}

export interface IPerk
  extends Pick<
  GetPerksAndConditions_lookup_attributes_defaultWeaponPerksSlots_perks,
    "name" | "weight"
  > {
  checked: boolean;
}

export interface IPerkSlot {
  probability: number;
  perks: IPerk[];
}

export interface IFormWeapon {
  id: string;
  weaponType: string;
  weaponSkin: string;
  nameCounterID: string;
  season: string;
  minMintLevel: number;
  dropType: string;
  rarity: RarityEnum;
  useSpecificModel: boolean;
  materials: IWeaponMaterial[];
  icon?: IImage;
  model?: IModelFile;
  perksSlot: IPerkSlot[];
  conditions: IPerk[];
  marketNameSkin: string;
}

export interface IWeaponMaterial {
  id: string;
  name: string;
  withNormalMap: boolean;
  withEmissionMap: boolean;
  withAmbientOcclusion: boolean;
  useSpecificParams: boolean;
  textures: ITexture[];
  json?: IJson;
}

export interface IFormWeaponProps {
  type?: "edit" | "new";
  onSubmit: (values: IFormWeapon) => void;
  initConfigWeaponProps?: IFormWeapon;
  errorMessage?: string;
}
