import { useState } from "react";
import { useMutation } from "@apollo/client";
import { IMrapketNamesProps } from "./Cgs.types";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { Accordion, AccordionSummary, AccordionDetails} from "./Accordion";
import { UPDATE_MARKETING_NAMES, DEPLOY_MARKETING_NAMES } from "mutations/cgsCollections";
import { useStyles } from "pages/CgsServise/Cgs.style";
import { v4 as uuidv4 } from "uuid";

export const MarketName = ({ marketNames, collectionID, refetchCollections }: IMrapketNamesProps) => {

  const classes = useStyles();
  const [expanded, setExpanded] = useState<string | boolean>("");
  const [currMarketNames, setMarktNames] = useState(marketNames?.map(item => ({...item, id: uuidv4()})) || []);

  const [updateMraketingNames] = useMutation(UPDATE_MARKETING_NAMES);

  const [deployMraketingNames] = useMutation(DEPLOY_MARKETING_NAMES);

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };

  const handleNameChange = (mName: string, id: string) => {
    const updatedNames = currMarketNames.map(item => item.id === id ? {...item, marketingName: mName} : item);
    setMarktNames(updatedNames);
  };

  return (
    <Accordion TransitionProps={{ unmountOnExit: true }} expanded={expanded === `${"market-names"}`} onChange={handleChange(`${"market-names"}`)} style={{ marginBottom: "8px"}}>
      <AccordionSummary aria-controls="panel1d-content" id="panel1d-header" style={{ width: "100%", backgroundColor: "LightSlateGrey"}}>
        <div className={classes.marketNamesHeader}>
          <span>Marketing Name</span>
          <span>Attribute Name</span>
          <span>Attribute Value</span>
        </div>
      </AccordionSummary>
      <AccordionDetails>
        <div>

          <Button 
            variant="contained"
            style={{margin: " 0 20px 6px"}}
            onClick={() => {
              updateMraketingNames({ variables: { values: currMarketNames.map(({id, ...restItem}) => restItem) } })
                .then(() => {
                  refetchCollections();
                  alert("successfull");
                })
                .catch(((e: Error) => alert(e.message)));
            }}
          >
            Update
          </Button>
          <Button 
            variant="contained"
            style={{marginBottom: "6px"}}
            onClick={() => {
              deployMraketingNames({ variables: { collectionID } })
                .then(() => alert("successfull"))
                .catch(((e: Error) => alert(e.message)));
            }}
          >
            Deploy
          </Button>
          {currMarketNames.map((item, i) => (<div className={classes.marketNames} key={i}>
            <TextField
              id="outlined-basic"
              label="Marketing name"
              variant="outlined"
              defaultValue={item.marketingName || ""}
              style={{ marginRight: "6px" }}
              onChange={(e) => handleNameChange(e.target.value, item.id)}
            />
            <span>{item.attributeName || "-"}</span>
            <span>{item.attributeValue || "-"}</span>
          </div>))}
        </div>
      </AccordionDetails>
    </Accordion>
  );
};