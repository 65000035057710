/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum CGSLogAction {
  All = "All",
  CollectionCreate = "CollectionCreate",
  CollectionDelete = "CollectionDelete",
  CollectionUpdate = "CollectionUpdate",
  PresetCreate = "PresetCreate",
  PresetDelete = "PresetDelete",
  PresetLock = "PresetLock",
  PresetUpdate = "PresetUpdate",
}

export enum InventoryItemKind {
  Item_GAMEDATA = "Item_GAMEDATA",
  Item_GENERATED = "Item_GENERATED",
}

export enum ItemType {
  CONFIGS = "CONFIGS",
  GAMEDATA = "GAMEDATA",
}

export interface MarketNameUpdateInput {
  attribute: string;
  value?: string | null;
  marketName: string;
}

export interface PerkSlotInput {
  probability: number;
  perks: VariationInput[];
}

export interface ResolveMarketNameInput {
  attribute: string;
  value?: string | null;
}

export interface RewardItemArbitraryCurrencyInput {
  type: string;
  amount: number;
}

export interface RewardItemCurrencyInput {
  orbs?: number | null;
  enrichedOrbs?: number | null;
  butter?: number | null;
  arbitraryCurrency?: RewardItemArbitraryCurrencyInput | null;
}

export interface RewardItemInput {
  inventory?: RewardItemInventoryInput | null;
  season?: RewardItemSeasonInput | null;
  token?: RewardItemTokenInput | null;
  currency?: RewardItemCurrencyInput | null;
}

export interface RewardItemInventoryInput {
  items: RewardItemInventoryItemInput[];
}

export interface RewardItemInventoryItemInput {
  id: any;
}

export interface RewardItemSeasonInput {
  points: number;
}

export interface RewardItemTokenInput {
  items: RewardItemTokenItemInput[];
}

export interface RewardItemTokenItemInput {
  id: any;
  configID: string;
  level: number;
}

export interface SingleWeaponInput {
  id: string;
}

export interface VariationInput {
  name: string;
  weight: number;
}

export interface WeaponConfigRandomizationInput {
  perksSlots: PerkSlotInput[];
  conditions: VariationInput[];
}

export interface WeaponConfigRenderInput {
  useSpecificModel: boolean;
  materials?: WeaponMaterialInput[] | null;
}

export interface WeaponMaterialInput {
  name: string;
  withNormalMap: boolean;
  withEmissionMap: boolean;
  withAmbientOcclusion: boolean;
  useSpecificParams: boolean;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
