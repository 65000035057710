import React, {useMemo} from "react";
import {getComparator, GetOrderBy, Order} from "utils/mui/sort";

type UseLocalSortAndSearchParams<T> = {
    items: T[];
}

export const useLocalSort = <T>({items}: UseLocalSortAndSearchParams<T>) => {
  const [order, setOrder] = React.useState<Order>("asc");
  const [getOrderBy, setGetOrderBy] = React.useState<GetOrderBy<T> | null>(null);
  const handleRequestSort = (
    newGetOrderBy: GetOrderBy<T>,
  ) => {
    const isAsc = getOrderBy === newGetOrderBy && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setGetOrderBy(() => newGetOrderBy);
  };


  const sortedData = useMemo(() => {
    if(!getOrderBy) {
      return items;
    }

    return items.slice().sort(getComparator(order, getOrderBy));

  }, [order, getOrderBy, items]);

  return {sortedData, handleRequestSort, order, getOrderBy};
};
