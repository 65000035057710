import { useState, ChangeEvent, useCallback, useEffect } from "react";
import { useLazyQuery } from "@apollo/client";
import { GET_ALL_USERS } from "components/SearchBar/query/getUsers.graphql";
import { debounce } from "lodash";

import { StyledTextField, Container, Circle, StyledCloseBtn } from "./SearchBar.styles";
import { GetUsers } from "components/SearchBar/query/__generated__/GetUsers";
import { DropDownMenu } from "./DropDownMenu";

export const SearchBar = () => {
  const [search, setSearch] = useState("");
  const [isActive, setFocusActive] = useState(false);
  const [activebtn, setActive] = useState<{[key: string]: boolean}>({
    username: true,
    discord: false,
    email: false,
  });

  const [getUsers, { data, loading }] = useLazyQuery<GetUsers>(GET_ALL_USERS);

  const fetch = useCallback(
    debounce((val: string) => getUsers({variables: {
      username: activebtn.username ? val :  "",
      email: activebtn.email ? val : "",
      discord: activebtn.discord ? val : "",
    }}), 500),
    [activebtn]
  );

  useEffect(() => {
    search && getUsers({variables: {
      username: activebtn.username ? search :  "",
      email: activebtn.email ? search : "",
      discord: activebtn.discord ? search : "",
    }});
  }, [activebtn]);

  return (
    <Container>
      <Circle />
      <StyledTextField
        value={search}
        id="search"
        autoComplete="off"
        placeholder="search"
        onFocus={() => setFocusActive(true)}
        onChange={(e: ChangeEvent<HTMLInputElement>) => {
          setSearch(e.target.value);
          e.target.value && fetch(e.target.value);
        }
        }
      />
      {search && <StyledCloseBtn onClick={() => setSearch("")} />}
      {isActive && <DropDownMenu 
        activeBtn={activebtn} 
        setFocusActive={setFocusActive}
        setActive={setActive}
        currenrtSearch={search}
        setSearch={setSearch}
        loading={loading}
        searchResult={data?.lookup.accounts.search?.items} 
      />}
    </Container>
  );
};