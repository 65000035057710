import React, {ReactNode, useState} from "react";
import {Box, Button, Grid, Typography} from "@mui/material";
import {TextField} from "@mui/material";
import MilitaryTechIcon from "@mui/icons-material/MilitaryTech";
import {toast} from "react-toastify";
import {getToastPromiseConfig} from "utils/toast";

const formatter = new Intl.NumberFormat("ru-RU");

interface MajorSendFieldProps {
  label: ReactNode;
  sendReward: (value: number) => Promise<unknown>;
  currentValue: number;
  inputLabel: ReactNode;
}

export const MajorSendField = ({label, sendReward, currentValue, inputLabel}: MajorSendFieldProps) => {
  const [inputValue, setInputValue] = useState<string>("");
  const handleClick = () => {
    toast.promise(sendReward(+inputValue || 0), getToastPromiseConfig());
  };
  return (
    <Grid item xs={12}>
      <Box sx={{background: "#F4F4F4", borderRadius: 2, padding: 2, display: "flex", gap: 2, alignItems: "center"}}>
        <Box width="50%">
          <Typography color="rgba(119, 119, 119, 1)">
            {label}
          </Typography>
          <Box display="flex" alignItems="center">
            <MilitaryTechIcon fontSize="large" />
            <Typography color="#333333" fontSize={30} component="span">{formatter.format(currentValue)}</Typography>
          </Box>

        </Box>
        <TextField
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          type="number"
          sx={{width: "30%"}}
          label={inputLabel}
          variant="outlined"
        />
        <Button disabled={!inputValue} onClick={handleClick} sx={{width: "20%", height: "56px"}} color="secondary" size="large" variant="contained">Send</Button>
      </Box>
    </Grid>
  );
};
