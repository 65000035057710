import { gql } from "@apollo/client";

export const DEPLOY_WEAPONS = gql`
      mutation WeaponConfigDeploy ($season: String, $weaponId: String) {
        weaponConfigDeploy(input: {
            season: $season,
            weaponId: $weaponId,
        }) {
    ok
  }
}
`;
