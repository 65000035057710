import { gql } from "@apollo/client";

export const GET_PRESETS_IDS = gql`
  query PresetsIds($id:  String!){
  lookup {
    cgsAdmin {
      collection(input: { id: $id }) {
      presets {
        id
  }
}

    }
  }
}
`;

