import { gql } from "@apollo/client";

export const ADD_REWARD_TEST = gql`
  mutation {
    rewardItems(
      input: {
        userID: "3010f9ad-6390-476f-ad5b-cc050ef29f50"
        reason: {
          id: "f783336c-bd01-48ff-92b6-c969f3e622e8"
          description: "This is a reasonable reason"
        }
        gamedataItems: [{ itemID: "be97e8e2-b361-47ef-9757-b449fd3091e5" }]
      }
    ) {
      ok
    }
  }
`;

export const ADD_GAMEDATA_REWARD = gql`
  mutation AddReward(
    $userId: UUID!
    $reasonId: UUID!
    $fromDesc: String!
    $itemID: UUID!
  ) {
    rewardItems(
      input: {
        userID: $userId
        reason: { id: $reasonId, description: $fromDesc }
        gamedataItem: { itemID: $itemID }
      }
    ) {
      ok
    }
  }
`;

export const ADD_MINTABLE_REWARD = gql`
  mutation AddReward(
    $userId: UUID!
    $reasonId: UUID!
    $fromDesc: String!
    $configID: String!
  ) {
    rewardItems(
      input: {
        userID: $userId
        reason: { id: $reasonId, description: $fromDesc }
        mintableItem: { configID: $configID }
      }
    ) {
      ok
    }
  }
`;
