import React, {useMemo, useState} from "react";
import {Button, Grid, Paper, Typography} from "@mui/material";
import {useUserProfileContext} from "pages/UserProfile/context";
import {getInventoryColumns} from "./utils";
import {ItemsCard} from "./components/ItemsCard";
import {EditModal} from "./components/EditModal";

export const Inventory = () => {
  const {data} = useUserProfileContext();
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [selectedItemId, setSelectedItemId] = useState<string | null>(null);
  if(!data.overview?.inventory?.items) {
    return null;
  }

  const handleModalClose = () => {
    setIsEditModalOpen(false);
    setSelectedItemId(null);
  };

  const handleSelectItemFromTable = (itemId: string) => {
    setSelectedItemId(itemId);
    setIsEditModalOpen(true);
  };

  const {gears, weapon, outfit} = getInventoryColumns(data.overview?.inventory?.items);

  const renderData = [
    {label: "Weapon", items: weapon},
    {label: "Outfit", items: outfit},
    {label: "Gears", items: gears},
  ];

  const selectedItem = useMemo(() => {
    return data.overview?.inventory?.items?.find(el => el.id === selectedItemId) || null;
  }, [data, selectedItemId]);

  console.log(selectedItem?.overview?.progress?.level);
  //todo add orbs
  return (
    <>
      <Grid mt={3} item xs={12}>
        <Paper sx={{p: 2}}>
          <Grid container spacing={3} justifyContent="space-between" alignItems="center">
            <Grid item>
              <Typography fontSize={16}>Inventory</Typography>
            </Grid>
            <Grid item>
              <Button onClick={() => setIsEditModalOpen(true)} sx={{background: "#E4E4E4", color: "black"}}>
                Edit inventory
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
      <Grid item  container spacing={2} xs={12}>
        {renderData.map(el => <ItemsCard onItemSelect={handleSelectItemFromTable} key={el.label} label={el.label} items={el.items}/>)}
      </Grid>
      {isEditModalOpen && (
        <EditModal selectedItem={selectedItem}  onItemSelect={setSelectedItemId} onClose={handleModalClose}/>
      )}
    </>
  );
};

