import React from "react";
import {DialogTitle, DialogTitleProps as MUIDialogTitleProps, IconButton} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

type DialogTitleProps = MUIDialogTitleProps & {
    onClose: () => void;
}

export const DialogTitleWithCross = ({children, onClose, ...other}: DialogTitleProps) => {
  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: "black"
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};
