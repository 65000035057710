import {createContext, useContext} from "react";
import {
  GetUser_lookup_accounts_resolve,
  GetUser_lookup_offchainWallets_allHolderAccounts
} from "./query/__generated__/GetUser";

export interface UserProfileContextType {
    data: GetUser_lookup_accounts_resolve;
    holderBalances: {
        butter: GetUser_lookup_offchainWallets_allHolderAccounts;
        orb: GetUser_lookup_offchainWallets_allHolderAccounts;
        enrichedOrb: GetUser_lookup_offchainWallets_allHolderAccounts;
    }
}

export const UserProfileContext = createContext<UserProfileContextType | null>(
  null
);

export const useUserProfileContext = (): UserProfileContextType => {
  const contextValue = useContext(UserProfileContext);
  if (!contextValue) {
    throw new Error("UserProfileContext.Provider is not found in tree");
  }
  return contextValue;
};
