import {useParams} from "react-router-dom";
import {useQuery} from "@apollo/client";
import {queryGetUser} from "./query/getUser.graphql";
import {Container, Grid, CircularProgress} from "@mui/material";
import Typography from "@mui/material/Typography";
import React from "react";
import {UserProfileContext} from "./context";
import {BaseInfo} from "./components/BaseInfo";
import {ClanInfo} from "./components/ClanInfo";
import {StatsInfo} from "./components/StatsInfo";
import {Inventory} from "./components/Inventory";
import {TopActions} from "./components/TopActions";
import {GetUser} from "pages/UserProfile/query/__generated__/GetUser";

export const UserProfile = () => {
  const {userId} = useParams();
  const { data: res, loading, error } = useQuery<GetUser>(queryGetUser, {
    variables: {
      id: userId,
    },
  });

  if(loading) {
    return <CircularProgress/>;
  }
  if(error || !res) {
    return (
      <Grid container>
        <Typography color="error">Error: ${error?.message}</Typography>
      </Grid>
    );
  }
  const data = res.lookup.accounts.resolve!;
  const holderBalances = {
    butter: res.lookup.offchainWallets.allHolderAccounts.find(el => el.type === "BUTTER")!,
    orb: res.lookup.offchainWallets.allHolderAccounts.find(el => el.type === "ORB")!,
    enrichedOrb: res.lookup.offchainWallets.allHolderAccounts.find(el => el.type === "ENRICHED_ORB")!,
  };


  return (
    <UserProfileContext.Provider value={{data, holderBalances}}>
      <Container maxWidth="lg">
        <Grid container>
          <Grid container item xs={12}>

            <Grid container item xs={6} spacing={2} alignItems="center">
              <Grid item>
                <Typography color="rgba(84, 84, 84, 1)">Players</Typography>
              </Grid>
              <Grid item>
                <Typography color="rgba(84, 84, 84, 0.5)">{data.userId}</Typography>
              </Grid>
            </Grid>

            <TopActions/>
          </Grid>
          <BaseInfo />
          <ClanInfo />
          <StatsInfo />
          <Inventory />
        </Grid>
      </Container>
    </UserProfileContext.Provider>
  );
};

