import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
} from "@mui/material";
import { useField } from "formik";

interface ISelectWrapperProps {
  opts: string[];
  name: string;
  label: string;
}

export const SelectWrapper: React.FC<ISelectWrapperProps> = ({
  opts,
  name,
  label,
}) => {
  const [field, meta] = useField(name);

  return (
    <FormControl fullWidth>
      <InputLabel id={name}>{label}</InputLabel>
      <Select
        labelId={name}
        name={name}
        id={name}
        label={label}
        value={field.value}
        onChange={field.onChange}
        error={meta.touched && Boolean(meta.error)}
      >
        {opts.map((item) => (
          <MenuItem value={item} key={item}>
            {item}
          </MenuItem>
        ))}
      </Select>
      {meta.error && meta.touched ? (
        <FormHelperText error={meta.touched && Boolean(meta.error)}>
          {meta.error}
        </FormHelperText>
      ) : null}
    </FormControl>
  );
};
