import { useState } from "react";
import Avatar from "@mui/material/Avatar";

import { Container, Content, Dots, Title, Subtitle } from "./UserCard.styles";
import { StyledPopup } from "components/Popup";
import { UserCardProps } from "./UserCard.types";

export const UserCard = ({ className, name, email, src = "" }: UserCardProps) => {

  const [anchorEl, setAnchorEl] = useState<SVGSVGElement | null>(null);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClickPopoverClick = (event: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
    setAnchorEl(event.currentTarget);
  };

  return (
    <Container className={className}>
      <Avatar sizes="40px" alt="Remy Sharp" sx={{border: "1px solid grey"}} src={src} />
      <Content>
        <Title>{name}</Title>
        <Subtitle>{email}</Subtitle>
      </Content>
      {/* <Dots onClick={handleClickPopoverClick}/> */}
      <StyledPopup 
        onClick={() => undefined}
        handleClose={handleClose}
        anchorEl={anchorEl}
      >
        <>
          <div>View</div>
          <div>Edit</div>
        </>
      </StyledPopup>
    </Container>
  );
};