import { gql } from "@apollo/client";

export const GET_LOGS = gql`
  query GetLogs( $offsetID: String!, $limit: Int!, $action: CGSLogAction!, $collectionID: String!, $presetID: String!) {
  lookup {
    cgsAdmin {
      logs(
        input: {
          offsetID: $offsetID
          limit: $limit
          action: $action
          collectionID: $collectionID
          presetID: $presetID
        }
      ) {
        logs {
          logID
          date
          batchID
          action
          collectionID
          presetID
          updates {
            key
            val
          }
        }
        nextID
      }
    }
  }
}
`;

