/* eslint-disable @typescript-eslint/no-explicit-any */
import Box from "@mui/material/Box";
import GoogleLogin from "react-google-login";
import {
  GoogleLoginResponse,
  GoogleLoginResponseOffline,
} from "react-google-login";
import { useNavigate } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { LOGIN } from "mutations/login";

const LoginPage = () => {
  const nav = useNavigate();
  const [logInUser] = useMutation(LOGIN);
  const onLoginSuccess = (
    auth: GoogleLoginResponse | GoogleLoginResponseOffline
  ) => {
    if ("profileObj" in auth && auth.profileObj.email.includes("faraway.gg")) {
      sessionStorage.setItem("auth", JSON.stringify(auth));
      logInUser({
        variables: {
          accessToken: auth.accessToken,
        },
      });
      nav("/dashboard");
    }
  };

  return (
    <Box
      alignItems="center"
      justifyContent="center"
      display="flex"
      height="100vh"
    >
      <GoogleLogin
        clientId="404386205762-6uk4m5cc911elba7embbukekf2ahqtav.apps.googleusercontent.com"
        buttonText="Login"
        onSuccess={onLoginSuccess}
        onFailure={(e) => {
          console.log(e);
        }}
        cookiePolicy={"single_host_origin"}
      />
    </Box>
  );
};

export default LoginPage;
