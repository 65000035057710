import { TextField, TextFieldProps } from "@mui/material";
import { useField } from "formik";

interface ITextFieldWrapperProps extends Omit<TextFieldProps, "name"> {
  name: string;
}

export const TextFieldWrapper: React.FC<ITextFieldWrapperProps> = ({
  name,
  label,
  InputProps,
  type,
  ...props
}) => {
  const [field, meta] = useField(name);

  return (
    <TextField
      id={name}
      label={label}
      variant="outlined"
      name={name}
      fullWidth
      value={field.value}
      onChange={field.onChange}
      onBlur={field.onBlur}
      helperText={meta.touched && meta.error ? meta.error : ""}
      error={meta.touched && Boolean(meta.error)}
      InputProps={InputProps}
      type={type}
      {...props}
    />
  );
};
