import React, {useState} from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import {Grid, Button} from "@mui/material";
import {client} from "apolloClient";
import {getConfigsQuery} from "../../../../../../query/getConfigs.graphql";
import {
  getConfigs as GetConfigsRes,
  getConfigs_lookup_itemConfigs_resolve_MintableItemConfig
} from "../../../../../../query/__generated__/getConfigs";
import AsyncSelect from "react-select/async";
import {ItemType} from "graphql-global-types/types";
import {toast} from "react-toastify";
import {getToastPromiseConfig} from "utils/toast";

const useGetLoadOptions = () => {
  return async (search: string) => {
    const {data} = await client.query<GetConfigsRes>({
      query: getConfigsQuery,
      variables: {
        query: search,
        itemType: ItemType.CONFIGS ///здесь было WEAPONS
      },
    });
    const res = data.lookup.itemConfigs.resolve!.map((el) => {
      const typedEl = el as getConfigs_lookup_itemConfigs_resolve_MintableItemConfig;
      return {
        value: typedEl.configID,
        label: typedEl.configID,
      };
    });
    return res;
  };
};


interface TabContentProps {
  onSend: (id: string) => Promise<unknown>
}


const TabContent = ({onSend}: TabContentProps) => {

  const loadOptions = useGetLoadOptions();
  const [selectedItemId, setSelectedItemId] = useState<string>();
  const handleClick = () => {
    toast.promise(onSend(selectedItemId!), getToastPromiseConfig());
  };
  return (
    <Box gap={1} display="flex" flexDirection="column" alignItems="flex-end">
      <AsyncSelect
        defaultOptions
        placeholder="Item id"
        isClearable
        menuPortalTarget={document.body}
        styles={{ container: (base) => ({...base, width: "100%"}),menuPortal: base => ({ ...base, zIndex: 9999 }), menuList: base => ({...base, maxHeight: 200}) }}
        onChange={(event) => {
          setSelectedItemId(event?.value);
        }}
        loadOptions={loadOptions}
      />

      <Button disabled={!selectedItemId} onClick={handleClick} color="secondary" variant="contained" sx={{width: 100}}>
        Send item
      </Button>
    </Box>
  );
};

interface ItemsTabsProps {
  onSend: (id: string) => Promise<unknown>
}

export const ItemsTabs = ({onSend}: ItemsTabsProps) => {
  const [value, setValue] = React.useState("weapons");

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };
  return (
    <Grid item xs={12}>
      <TabContext value={value}>
        <Box sx={{ borderColor: "divider" }} mb={2}>
          <TabList onChange={handleChange}>
            <Tab label="Weapons" value="weapons" />
          </TabList>
        </Box>
        <TabPanel sx={{padding: 0}} value="weapons"><TabContent onSend={onSend}/></TabPanel>
      </TabContext>
    </Grid>
  );
};
