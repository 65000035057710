import { gql } from "@apollo/client";

export const GET_FILESTREE = gql`
    {
  lookup {
    cgsAdmin {
      files {
        isPart,
        name,
        attributeName,
        attributeValue,
        errors,
        children {
          isPart,
          name,
          attributeName,
          attributeValue,
          errors,
            children {
            isPart,
            name,
            attributeName,
            attributeValue,
            errors,
                children {
                isPart,
                name,
                attributeName,
                attributeValue,
                errors,
                    children {
                    isPart,
                    name,
                    attributeName,
                    attributeValue,
                    errors,
                        children {
                        isPart,
                        name,
                        attributeName,
                        attributeValue,
                        errors,
                                children {
                            isPart,
                            name,
                            attributeName,
                            attributeValue,
                            errors,
                            }
                        }
                    }
                }
            }
        }
      }

    }
  }
}
`;

export const GET_COLLECTIONS = gql`
  {
  lookup {
    cgsAdmin {
      collections {
        id,
     		userText,
        params {
          name,
          family,
          description,
          tokenNameTemplate,
          tokenNameTemplate,
          season,
          nameCounter,
          dropType,
          },
      }

    }
  }
}
`;

export const GET_COLLECTION = gql`
  query search($id:  String!){
  lookup {
    cgsAdmin {
      collection(input: { id: $id }) {
      presets {
        id
        tokenAmount
        name
        currentTokenAmount
      }
  marketingNames {
    attributeName
    attributeValue
    marketingName
  }
  hiddenAttributes {
    name
    probability
    values {
      name
      weight
    }
  }
}

    }
  }
}
`;

export const GET_TASK_STATUS = gql`
  query {
  lookup {
    cgsAdmin {
      currentTaskProgress {
        task {
          id
          number
          description
          status
          error
          progressNow
          progressMax
          nftContentSha
        }
        totalTasksInQueue
      }
    }
  }
}
`;

export const GET_TASKS = gql`
query search($offset:  Int!, $limit: Int!) {
  lookup {
    cgsAdmin {
      tasks(input: { offset: $offset, limit: $limit }) {
        id
        number
        description
        error
        progressNow
        progressMax
        createDate
        status
        nftContentSha
      }
    }
  }
}
`;

export const GET_PRESET = gql`
query search($id:  String!, $presetID: String!){
  lookup {
    cgsAdmin {
      collection(input: { id: $id }) {
        preset(input: { presetID: $presetID }) {
          id
        collectionID
        name
        baseRarity
        minMintLevel
        iconTokenID
        background
        tokenAmount
        iconsAttempt
        generationLock
        icons {
          tokenID
        }
        currentTokenAmount
        attributes {
                name,
                probability,
                values {
                  name,
                  weight,
                }
              }
        }
}

    }
  }
}
`;

export const GET_ICONS_ATTEMPTS = gql`
query search($id:  String!){
  lookup {
    cgsAdmin {
      collection(input: { id: $id }) {
        iconAttempts
}
    }
  }
}
`;