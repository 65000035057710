import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
} from "@mui/material";
import { mapEnum } from "utils/mapEnum";
import { RarityEnum } from "../types";
import { useField } from "formik";

export const SelectRarity: React.FC = () => {
  const [field, meta] = useField("rarity");

  return (
    <FormControl fullWidth>
      <InputLabel id="rarity">Rarity</InputLabel>
      <Select
        labelId="rarity"
        id="rarity-select"
        name="rarity"
        label="rarity"
        value={field.value}
        onChange={field.onChange}
        error={meta.touched && Boolean(meta.error)}
      >
        {mapEnum(RarityEnum, (key, value) => (
          <MenuItem value={value} key={key}>
            {key}
          </MenuItem>
        ))}
      </Select>
      {meta.error && (
        <FormHelperText error={meta.touched && Boolean(meta.error)}>
          {meta.error}
        </FormHelperText>
      )}
    </FormControl>
  );
};
