import { styled } from "@mui/system";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { getServerColor } from "../Layout/Layout.styles";

export const Container = styled("div")({
  display: "flex",
  padding: "14px 11px",
  backgroundColor: getServerColor(),
  alignItems: "center",
  borderRadius: "4px",
  position: "relative",
  border: "1px solid lightGrey"
});

export const Content = styled("div")({
  marginLeft: "8px"
});

export const Title = styled("div")({
  fontSize: "14px",
  fontWeight: 500,
  marginBottom: "2px",
});

export const Subtitle = styled("div")({
  color: "#777777",
  fontSize: "14px",
  textOverflow: "ellipsis",
  maxWidth: "140px",
  overflow: "hidden",
});

export const Dots = styled(MoreVertIcon)({
  cursor: "pointer",
  position: "absolute",
  top: 10,
  right: 12,
});